import React, { useState, useEffect } from 'react'
import { EXTERNAL_URLS } from 'config'

import useContracts from "hooks/useContracts";
import YogiesStaking from "adapters/yogiesStaking";
import YogiesHouse from "adapters/yogiesHouse";

import { formatNumber, getErrorMessage } from "utils";
import { ToastContainer, toast } from 'react-toastify';

export default function BoostCard({ 
    provider,
    itemType,
    itemAmount,
    houseAmount,
    stakedHouses,
    unstakableHouses,
    fetchContracts,
    mansionApprovedForAll
}) {

    const [isApproveTx, setIsApproveTx] = useState();
    const [isActivateTx, setIsActivateTx] = useState();
    const [isDeActivateTx, setIsDeActivateTx] = useState();

    const [approveTxHash, setApproveTxHash] = useState();
    const [activateTxHash, setActivateTxHash] = useState();
    const [deActivateTxHash, setDeActivateTxHash] = useState();

    const [txError, setTxError] = useState();
    const [txSuccess, setTxSuccess] = useState();

    const [activationAmount, setActivationAmount] = useState(1);

    useEffect(() => {

    })

    useEffect(() => {
        if (txError) {
            toast.error(txError, {
                position: "top-right",
                autoClose: 16000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }          
    }, [txError])

    useEffect(() => {
        if (txSuccess) {
            toast.success(txSuccess, {
                position: "top-right",
                autoClose: 16000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }, [txSuccess])

    const  [
        ,
        ,
        ,
        contractHouse,
        ,
        ,
        ,
        ,
        contractYogiesStaking,
        ,
    ] = useContracts(provider);

    const carAmountToBoost = num => {
        if (num === 1) {
            return 20;
        } else if (num === 2) {
            return 35;
        } else if (num === 3) {
            return 50;
        } else if (num > 3) {
            return 50;
        }

        return 0;
    }

    const getNameFromType = type => {
        if (type === 0) {
            return "Mansion"
        } else if (type === 1) {
            return "Car"
        } else if (type === 2) {
            return "Monkey"
        } else if (type === 3) {
            return "Cat"
        } else if (type === 4) {
            return "Dog"
        }
    }

    const getStatusMessageFromType = type => {
        if (type === 0) {
            return stakedHouses.eq(0) ? 1 + " SLOT" : stakedHouses.mul(10).toString() + " SLOTS"
        } else if (type === 1) {
            return carAmountToBoost(itemAmount.toNumber()) + "% BOOST"
        } else if (type === 2) {
            return "SECURE"
        } else if (type === 3) {
            return "SECURE"
        } else if (type === 4) {
            return "SECURE"
        }
    }

    const imageUrl = itemType !== undefined ? EXTERNAL_URLS.yogie_items_image_base_url + getNameFromType(itemType).toLowerCase() + ".png" : "";

    async function onApproveActivation() {
        if (!isApproveTx && contractHouse && contractYogiesStaking) {
            setIsApproveTx();
            setApproveTxHash();
            setTxError();
            setTxSuccess();
            
            const yogiesHouseObj = new YogiesHouse(contractHouse, provider.signer);

            try {
                setIsApproveTx(true);
                let tx = await yogiesHouseObj.setApprovalForAll(contractYogiesStaking.address, true);
                setApproveTxHash(tx.hash);
                await tx.wait();
                setTxSuccess(`Successfully approved activation of mansions`);
            } catch (e) {
                setTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setIsApproveTx();
            setApproveTxHash();
        }
    }

    async function onStakeAll() {
        if (!isActivateTx && contractYogiesStaking) {
            setIsActivateTx();
            setActivateTxHash();
            setTxError();
            setTxSuccess();
            
            const yogiesStakingObj = new YogiesStaking(contractYogiesStaking, provider.signer);
            let mansionsOfUser = await yogiesStakingObj.getMansionsOfUser(provider.address);

            let toActivate = parseInt(activationAmount);
            if (isNaN(toActivate)) {
                setTxError("Please select a valid mansion amount");
                return;
            }

            if (toActivate < 1) {
                setTxError("You can not activate less than one mansion")
                return;
            }
            
            if (!mansionApprovedForAll) {
                setTxError(`
                    You need to approve the activation of your mansions first before you can activate them. 
                    By pressing the approve button you are approving the Yogies Staking Contract to transfer your mansions to the staking address. 
                    You can always get your houses back by de-activating them.
                `);
                return;
            }

            if (toActivate > mansionsOfUser.length) {
                setTxError(`
                    The maximum amount of mansions you can activate is ${mansionsOfUser.length}.
                `);
                return;
            }

            try {
                setIsActivateTx(true);
                let tx = await yogiesStakingObj.stakeHouses(mansionsOfUser.slice(0, toActivate));
                setActivateTxHash(tx.hash);
                await tx.wait();
                setTxSuccess(`Mansions were successfully activated`);
            } catch (e) {
                setTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setIsActivateTx();
            setActivateTxHash();
        }
    }

    async function onUnStakeAll() {
        if (!isDeActivateTx && contractYogiesStaking) {
            setIsDeActivateTx();
            setDeActivateTxHash();
            setTxError();
            setTxSuccess();
            
            const yogiesStakingObj = new YogiesStaking(contractYogiesStaking, provider.signer);
            let mansionsOfUser = await yogiesStakingObj.getStakedMansionsOfUser(provider.address);
            let unstakableMansions = await yogiesStakingObj.getUnstakableMansions(provider.address);

            let toActivate = parseInt(activationAmount);
            if (isNaN(toActivate)) {
                setTxError("Please select a valid mansion amount");
                return;
            }

            if (toActivate < 1) {
                setTxError("You can not de-activate less than one mansion")
                return;
            }
            
            if (mansionsOfUser.length === 0) {
                setTxError(`You don't have any activated mansions. Therefore you can not de-activate any mansions.`)
                return;
            }

            
            if (mansionsOfUser.length < toActivate) {
                setTxError(`The maximum amount of mansions you can de-activate is ${mansionsOfUser.length}`);
                return
            }

            if (unstakableHouses.lt(toActivate)) {
                if (unstakableHouses.eq(0)) {
                    setTxError(`
                        You cannot deactivate your mansion at this time. 
                        Please unstake a couple of Yogies to be able to deactivate your mansion. 
                        Each mansion is 10 staking slots.
                    `)
                } else {
                    setTxError(`
                        You can not deactivate more than ${unstakableHouses.toString()} at this time. 
                        Please unstake a couple of Yogies to be able to deactivate your mansion. 
                        Each mansion is 10 staking slots.
                    `)
                }
                return;                
            }

            try {
                setIsDeActivateTx(true);
                let tx = await yogiesStakingObj.unStakeHouses(mansionsOfUser.slice(0, toActivate))
                setDeActivateTxHash(tx.hash);
                await tx.wait();
                setTxSuccess(`Mansions were successfully deactivated`);
            } catch (e) {
                setTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setIsDeActivateTx();
            setDeActivateTxHash();
        }
    }

    return (
        <>            
           <div className="box_staking">
               <img src={imageUrl} style={{borderRadius: "20px"}}/>
                <div className="yogienumber">{itemType !== undefined ? getNameFromType(itemType) : 0}
                
                    <div className="earning2">
                        <div className="earning">{itemType !== undefined ? getStatusMessageFromType(itemType) : 0}</div>
                    </div>
                    {
                        itemType === 0 &&
                        <div className="yogienumber4" style={{marginTop: "5px", marginRight: "5px"}}>
                            <span onClick={() => setActivationAmount(_ => _ - 1 < 1 ? 1 : _ - 1)} style={{cursor: "pointer"}}>-</span> {activationAmount} 
                             <span onClick={() => setActivationAmount(_ =>  _ + 1)} style={{cursor: "pointer"}}> +</span>
                        </div>
                    }                    
                </div>

                {
                    itemType === 0 ?
                    <>  
                        { 
                            <>

                                {
                                    !mansionApprovedForAll && 
                                    <div className="button_active" style={{
                                        cursor: "pointer", 
                                        border: "2px solid #ff8080", 
                                        background: "#a70000"
                                    }}  onClick={() => {
                                            if (isApproveTx)
                                                window.open(EXTERNAL_URLS.etherscan + approveTxHash, "_blank")
                                            else
                                                onApproveActivation()
                                    }}>{!isApproveTx ? "APPROVE" : "VIEW TRANSACTION"}</div>
                                }

                                {
                                    itemAmount && itemAmount.gt(0) &&
                                        <div 
                                            className="button_active" 
                                            onClick={() => {
                                                if (isActivateTx)
                                                    window.open(EXTERNAL_URLS.etherscan + activateTxHash, "_blank")
                                                else
                                                    onStakeAll()
                                            }} 
                                            style={{
                                                cursor: "pointer", 
                                                border: mansionApprovedForAll ? "2px solid rgb(255 177 96)" : "2px solid rgb(205 205 205 / 29%)", 
                                                background: mansionApprovedForAll ? "rgb(211 99 4)" : "rgb(86 86 86 / 20%)"
                                            }}
                                        >{!isActivateTx ? "ACTIVATE" : "VIEW TRANSACTION"} {!isActivateTx ? activationAmount : null}</div>
                                }                                

                                {
                                    unstakableHouses &&
                                    <div className="button_active" onClick={() => {
                                        if (isDeActivateTx)
                                            window.open(EXTERNAL_URLS.etherscan + activateTxHash, "_blank")
                                        else
                                            onUnStakeAll()
                                    }} style={{
                                        cursor: "pointer", 
                                        border: "2px solid rgb(195 195 195)", 
                                        background: "rgb(80 80 80)"
                                    }}>{!isDeActivateTx ? "DEACTIVATE" : "VIEW TRANSACTION"} {!isDeActivateTx ? activationAmount : null}</div>
                                } 
                            </>
                        }                                               
                    </>
                    :
                    <>
                        {
                            itemAmount > 0 &&
                            <>                            
                                <div className="button_active">ACTIVE</div>
                                {
                                     !mansionApprovedForAll ?
                                    <div 
                                        class="button_active" 
                                        style={{cursor: "pointer", border: "2px solid rgb(255 128 128 / 0%)", background: "rgb(167 0 0 / 0%)", color: "#0000"}}>
                                    </div>
                                    :
                                    null
                                }
                                {
                                    houseAmount && houseAmount.gt(0) ?
                                    <div 
                                        class="button_active" 
                                        style={{cursor: "pointer", border: "2px solid rgb(255 128 128 / 0%)", background: "rgb(167 0 0 / 0%)", color: "#0000"}}>
                                    </div>
                                    : 
                                    null
                                }
                            </>
                            
                        }
                    </>                    
                }

                <ToastContainer
                    position="top-right"
                    autoClose={16000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />      
            </div>                
        </>
    )
}