import React, { useState, useEffect } from "react"

import ProviderHOC from "hoc/provider";
import Header from "components/header";
import Footer from "components/footer";
import ConnectSection from "components/connectSection";

import { ethers } from "ethers";

import useContracts from "hooks/useContracts";
import Yogies from "adapters/yogies";
import YogiesAttack from "adapters/yogiesAttack";
import YogiesStaking from "adapters/yogiesStaking";
import { formatNumber, removeDecimals } from "utils";

import MobileError from "components/mobileError";
import Loader from "components/loader";
import WrongNetwork from "components/wrongNetwork";

import { getUnixTimeStamp } from "utils";

function Profile({ provider }) { 
    const isConnected = !!provider?.address;

    const  [
        wrongNetwork,
        contractYogies,
        ,
        ,
        ,
        ,
        ,
        ,
        contractYogiesStaking,
        contractYogiesAttack,
    ] = useContracts(provider);

    const [yogieBalance, setYogieBalance] = useState();
    const [vaultYogieBalance, setVaultYogieBalance] = useState();
    const [viyYogieBalance, setViyYogieBalance] = useState();
    const [genesisBalance, setGenesisBalance] = useState();

    const [totalEarnings, setTotalEarnings] = useState();
    const [leaderBoardPosition, setLeaderBoardPosition] = useState("?");
    const [cooldown, setCooldown] = useState();
    const [stolen, setStolen] = useState();

    const [loaded, setLoaded] = useState();

    function isContractLoaded() {
        return provider && contractYogies && contractYogiesStaking && contractYogiesAttack;
    }

    async function fetchContracts() {
        let yogiesObj = new Yogies(contractYogies, provider.signer);
        let yogiesAttackObj = new YogiesAttack(contractYogiesAttack, provider.signer);       
        let yogiesStakingObj = new YogiesStaking(contractYogiesStaking, provider.signer);
        
        let vaultStart = await yogiesObj.vaultStartPoint();
        let viyStart = await yogiesObj.viyStartPoint();
        
        let userYogies = (await yogiesObj.getYogiesOfUser(provider.address)).filter(s => !s.eq(0));
        let userStakedYogies = (await yogiesObj.getStakedYogiesOfUser(provider.address)).filter(s => !s.eq(0));
        userYogies = userYogies.concat(userStakedYogies);

        let userGenesis = (await yogiesObj.getGYogiesOfUser(provider.address)).filter(s => !s.eq(0));
        let userStakedGenesis = (await yogiesObj.getStakedGYogiesOfUser(provider.address)).filter(s => !s.eq(0));
        userGenesis = userGenesis.concat(userStakedGenesis)
        setGenesisBalance(userGenesis.length)

        let userNormalYogies = vaultStart.gt(0) ? userYogies.filter(y => y.lt(vaultStart)) : userYogies.filter(y => y.lt(viyStart));                
        setYogieBalance(userNormalYogies.length);
        
        let userVaultYogies = vaultStart.gt(0) ? userYogies.filter(y => y.gte(vaultStart) && y.lt(viyStart)) : [];
        setVaultYogieBalance(userVaultYogies.length);

        let userVIYYogies = userYogies.filter(y => y.gte(viyStart));
        setViyYogieBalance(userVIYYogies.length)

        let totalStakingReward = await yogiesStakingObj.getAccumulatedGemies(provider.address);

        let totalStolen = await yogiesAttackObj.userToStolen(provider.address);

        let cooldownSeconds = await yogiesAttackObj.userToAttackCooldown(provider.address);

        setTotalEarnings(totalStakingReward.add(totalStolen));
        setStolen(totalStolen);
        let now = getUnixTimeStamp();
        let _cooldown = cooldownSeconds.lt(now) ? 0 : cooldownSeconds.sub(now).div(60).toString()
        setCooldown(_cooldown);

        setLoaded(true);
    }   

    useEffect(() => {
        if (isContractLoaded()) {
            fetchContracts();
          provider.instance.on("block", (_) => {
            fetchContracts();
          });
          return () => provider.instance.off("block");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractYogies, contractYogiesStaking, contractYogiesAttack]);

    return (
        <>
            <MobileError />

            <div className="notsupport">
                {
                    isConnected && !wrongNetwork &&
                    <Header 
                        active={0}
                    />
                }

                {
                    isConnected && !wrongNetwork ? 
                    <div className="box_dash">
                        <Loader text={"Loading your profile"} show={!loaded} />

                        {
                            loaded &&
                            <>
                                <div className="box_dash8">
                                <div className="box_balance8">{totalEarnings ? formatNumber(removeDecimals(totalEarnings)) : 0}</div>TOTAL EARNINGS</div>
                                <div className="box_dash4">
                                <div className="box_balance4">{yogieBalance ? formatNumber(yogieBalance.toString()) : 0}</div>REGULAR YOGIES</div>
                                <div className="box_dash8">
                                <div className="box_balance8">{leaderBoardPosition}</div>LEADERBOARD POSITION</div>
                                <div className="box_dash4">
                                <div className="box_balance4">{vaultYogieBalance ? formatNumber(vaultYogieBalance.toString()) : 0}</div>VAULT YOGIES</div>
                                <div className="box_dash8">
                                <div className="box_balance8">{cooldown ? cooldown : 0}</div>TOTAL MINUTES COOLDOWN</div>
                                <div className="box_dash6">
                                <div className="box_balance6">{viyYogieBalance ? formatNumber(viyYogieBalance.toString()) : 0}</div>VERY IMPORTANT YOGIES</div>
                                <div className="box_dash8">
                                <div className="box_balance8">{stolen ? formatNumber(removeDecimals(stolen)) : 0}</div>EARNED FROM ATTACKS</div>
                                <div className="box_dash7">
                                <div className="box_balance7">{genesisBalance ? formatNumber(genesisBalance.toString()) : 0}</div>GENESIS YOGIES</div>
                            </>
                        }                        
                        <Footer />
                    </div>
                    :
                    wrongNetwork ?
                    <>
                        <WrongNetwork current={wrongNetwork} provider={provider} />
                        <Footer /> 
                    </>
                    :
                    <>
                        <ConnectSection />
                        <Footer />                    
                    </>                
                }
            </div>            
        </>
    )
}

export default ProviderHOC(Profile)