import React, { useState } from "react"
import { 
    getTruncatedAddress,
    formatSeconds
} from "../../utils"

import { Oval } from  'react-loader-spinner'

export default function LeaderboardRow({ 
    rank, 
    address, 
    gpd, 
    cooldownLeft, 
    attackUser, 
    now, 
    petsBalance 
}) {
    let cooldownRealLeft = cooldownLeft - now;

    const [loading, setLoading] = useState();

    return (
        <>
           <div class="box_lb3">
                <div class="box_lb4">{rank}</div>
                <text>{address ? getTruncatedAddress(address) : ""}</text>
                <text>{gpd} $Gemies</text>
                {/*<text>22 Items</text>*/}
                {
                    cooldownRealLeft > 0 ?
                        <text>{formatSeconds(cooldownRealLeft)} Left</text>
                    :
                        petsBalance && petsBalance > 0 ?
                            <div class="attack" style={{
                                cursor: "pointer", 
                                backgroundColor: "#00a785", 
                                border: "2px solid #80ffc9"
                            }}>SECURE</div>
                        :
                            <div class="attack" style={{cursor: "pointer"}} onClick={async () => {
                                setLoading(true)
                                await attackUser(address)
                                setLoading(false)
                            }}>
                                {
                                    loading ?
                                    <Oval
                                        height="20px"
                                        width="20px"
                                        color='#ffc520'
                                        ariaLabel='loading'                                        
                                    />     
                                    : 
                                    "ATTACK"
                                }
                            </div>
                }                                
            </div>
        </>
    )
}