export default class YogiesAttack {
    constructor(instance, signer) {
        this.instance = instance.connect(signer);
    }

    mintYogies(proof, stake) {
        return this.instance.mintYogies(proof, stake);
    }

    userToCooldown(address) {
        return this.instance.userToCooldown(address);
    }

    userToAttackCooldown(address) {
        return this.instance.userToAttackCooldown(address);
    }

    attack(address, providedYogie=0) {
        return this.instance.attack(address, providedYogie);
    }

    userToStolen(address) {
        return this.instance.userToStolen(address)
    }
}