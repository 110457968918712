import React from 'react';
import Index from "pages/index.jsx"

function App() {
  return (
    <div>
        <Index />
    </div>
  );
}

export default App;
