import { ethers } from "ethers";
import { useEffect, useState } from "react";

import { getContractConfig } from "utils";
import { CONTRACTS, NETWORK } from "config";

export default function useContracts(provider, key) {
  const [wrongNetwork, setWrongNetwork] = useState();
  const [contractYogies, setContractYogies] = useState(null);  
  const [contractYogiesGenesis, setContractYogiesGenesis] = useState(null);
  const [contractHouse, setContractHouse] = useState(null);
  const [contractCar, setContractCar] = useState(null);
  const [contractPets, setContractPets] = useState(null); 
  const [contractYogiesItemShop, setContractYogiesItemShop] = useState(null);  
  const [contractGemies, setContractGemies] = useState(null);  
  const [contractYogiesStaking, setContractYogiesStaking] = useState(null);
  const [contractYogiesAttack, setContractYogiesAttack] = useState(null);

  useEffect(() => {
    if (provider) {
      (async function () {
        const contractData = {};
        let isCorrectChainId = provider.chainId === NETWORK.TARGET_CHAIN_ID;
     
        if (isCorrectChainId) {
          Object.keys(CONTRACTS).forEach((item) => {
            const config = getContractConfig(
              provider.chainId.toString(),
              CONTRACTS[item]
            );
           
            if (config) {
              contractData[CONTRACTS[item]] = new ethers.Contract(
                config.address,
                config.abi,
                provider.instance
              );
            }
          });
        } else {
            setWrongNetwork(NETWORK.CHAIN_ID_TO_NAME[provider.chainId])
        }
       
        setContractYogies(contractData[CONTRACTS.YOGIES]);
        setContractYogiesGenesis(contractData[CONTRACTS.YOGIES_GENESIS]);

        setContractHouse(contractData[CONTRACTS.HOUSE]);
        setContractCar(contractData[CONTRACTS.CAR]);
        setContractPets(contractData[CONTRACTS.PETS]);

        setContractYogiesItemShop(contractData[CONTRACTS.YOGIES_ITEM_SHOP]);
        setContractGemies(contractData[CONTRACTS.GEMIES]);
        setContractYogiesStaking(contractData[CONTRACTS.YOGIES_STAKING]);
        setContractYogiesAttack(contractData[CONTRACTS.YOGIES_ATTACK]);
      })();
    }
  }, [provider, key]);

  return [
    wrongNetwork,
    contractYogies,
    contractYogiesGenesis,
    contractHouse,
    contractCar,
    contractPets,
    contractYogiesItemShop,
    contractGemies,
    contractYogiesStaking,
    contractYogiesAttack
  ];
}
