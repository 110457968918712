import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import "index.css";


import { ProviderProvider } from "./context/provider";
import { providerReducer, initialState as provider } from "./reducers/provider";

ReactDOM.render(
  <React.StrictMode>
    <ProviderProvider initialState={provider} reducer={providerReducer}>
      <App />
    </ProviderProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
