export const NETWORK = {
    TARGET_CHAIN_ID: 1,
    CHAIN_ID_TO_NAME: {
        "1": "mainnet",
        "3": "ropsten",
        "4": "rinkeby",
        "42": "kovan",
        "1337": "localhost"
    },
    INFURA_ID: "2ca7c361086740d98cafc1dff241c125",
    PORTIS_ID: "",
    FORTMATIC_ID: ""
}

export const CONTRACTS = {
    YOGIES: "Yogies",
    YOGIES_GENESIS: "YogiesGenesis",
    HOUSE: "YogiesHouse",
    CAR: "YogiesCar",
    PETS: "YogiesPets",
    YOGIES_ITEM_SHOP: "YogiesItemShop",
    GEMIES: "Gemies",
    YOGIES_STAKING: "YogiesStaking",
    YOGIES_ATTACK: "YogiesAttack"
}

export const DATA = {
    UPDATE_START: 1652198400, // Tue May 10 2022 18:00:00 GMT+0200 (Central European Summer Time)
    COUNTDOWN_ACTIVE: true
}

export const EXTERNAL_URLS = {
    etherscan: `https://etherscan.io/tx/`,
    opensea: "https://opensea.io/collection/yogies",
    looksrare: "https://looksrare.org/collections/0xCdD2c026B7c5eFA9222C9050A1D1C72C56768442",
    discord: "https://discord.com/invite/yogies",
    dashboard: "https://app.yogies.club",
    yogie_image_base_url: "https://yogies.mypinata.cloud/ipfs/QmYqvP5TzQJFRHAx22efPdp36UmmYzNSBBA5dimYGvvUd7/",
    vault_image_base_url: "https://yogies.mypinata.cloud/ipfs/QmYqvP5TzQJFRHAx22efPdp36UmmYzNSBBA5dimYGvvUd7/",
    viy_image_base_url: "https://yogies.mypinata.cloud/ipfs/QmYqvP5TzQJFRHAx22efPdp36UmmYzNSBBA5dimYGvvUd7/",
    yogie_genesis_base_url: "https://storage.googleapis.com/yogies-assets/images/genesis/",
    yogie_items_image_base_url: "https://storage.googleapis.com/yogies-assets/images/items/"
}

export const FIRESTORE = {
    "1": {
        
    },
    "42": {
        
    },
    "1337": {
        
    }
}

export const ERRORS = {
    MESSAGES: [
        {
            error: "HAVE TO BUY AT LEAST 1",
            message: "Error: Your transaction has not gone through. You have to buy at least one ticket"
        },
        {
            error: "MetaMask Tx Signature: User denied transaction signature",
            message: "Error: You rejected the transaction."
        },
        {
            error: "insufficient funds for gas * price + value",
            message: "Error: You don't have enough Ether in your wallet to complete the transaction.",
            insert: true
        },
        {
            error: "INVALID PROOF",
            message: "Error: You are not on the white list. Please contact the team if you think this is incorrect."
        },
        {
            error: "User not whitelisted",
            message: "Error: You are not on the white list."
        },
        {
            error: "Freemint closed",
            message: "Minting is not opened yet"
        }
    ]
}