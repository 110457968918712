export default class YogiesStaking {
    constructor(instance, signer) {
        this.instance = instance.connect(signer);
    }

    mintYogies(proof, stake) {
        return this.instance.mintYogies(proof, stake);
    }

    yogieBaseType() {
        return 0;
    }

    vaultYogieType() {
        return 1;
    }

    viyYogieType() {
        return 2;
    }

    gYogieType() {
        return 3;
    }

    yogieTypeToYield(type) {
        if (type === 0) {
            return 10;
        } else if (type === 1) {
            return 15;
        } else if (type === 2) {
            return 30;
        } else if (type === 3) {
            return 45;
        }

        return  0;
    }

    carBonus(amount) {
        if (amount === 1) {
            return 20;
        } else if (amount === 2) {
            return 35;
        } else if (amount === 3) {
            return 50;
        } else if (amount > 3) {
            return 50;
        }

        return 0;
    }

    stakeSingleYogie(yogieId, type) {
        return this.instance.stakeSingleYogie(yogieId, type);
    }

    unStakeSingleYogie(yogieId, type) {
        return this.instance.unStakeSingleYogie(yogieId, type);
    }

    stakeManyYogies(yogieIds, types) {
        return this.instance.stakeManyYogies(yogieIds, types);
    }

    unStakeManyYogies(yogieIds, types) {
        return this.instance.unStakeManyYogies(yogieIds, types);
    }

    getTotalStakedYogies(user) {
        return this.instance.getTotalStakedYogies(user);
    }

    getDailyReward(user) {
        return this.instance.getDailyReward(user);
    }

    getAccumulatedGemies(user) {
        return this.instance.getAccumulatedGemies(user);
    }

    stakedHouseAmount(address) {
        return this.instance.stakedHouseAmount(address);
    }

    stakeHouses(ids) {
        return this.instance.stakeHouses(ids);
    }

    unStakeHouses(ids) {
        return this.instance.unstakeHouses(ids);
    }

    getMansionsOfUser(address) {
        return this.instance.getMansionsOfUser(address);
    }

    getStakedMansionsOfUser(address) {
        return this.instance.getStakedMansionsOfUser(address);
    }

    getUnstakableMansions(address) {
        return this.instance.getUnstakableMansions(address);
    }
}