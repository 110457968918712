export default class YogiesItemShop {
    constructor(instance, signer) {
        this.instance = instance.connect(signer);
    }

    buyHouses(amount, providedYogie, isGenesis) {
        return this.instance.buyHouse(amount, providedYogie, isGenesis);
    }

    buyCars(amount, providedYogie, isGenesis) {
        return this.instance.buyCar(amount, providedYogie, isGenesis)
    }

    buyPets(amount, providedYogie, isGenesis) {
        return this.instance.buyPets(amount, providedYogie, isGenesis)
    }

    housePrice() {
        return this.instance.housePrice();
    }

    carPrice() {
        return this.instance.carPrice();
    }

    petPrice() {
        return this.instance.petPrice()
    }

    housesLeft() {
        return this.instance.housesLeft();
    }

    carsLeft() {
        return this.instance.carsLeft();
    }

    petsLeft() {
        return this.instance.petsLeft();
    }

    gYogieDiscount() {
        return this.instance.gYogieDiscount()
    }

    VIYDiscount() {
        return this.instance.VIYDiscount()
    }

    balanceOf(address, item) {
        return this.instance.balanceOf(address, item)
    }
}