import React from "react"

export default function FloatingObjects() {
    return (
        <>
            <div class="float_shpe1" style={{maxWidth: "7%", top: "19%", left: "3%"}}>
                <img src="images/6.png" alt="" />
            </div>
            <div class="float_shpe2" style={{maxWidth: "5%", right: "15%", animation: "lighting 45s linear infinite reverse"}}>
                <img src="images/5.png" alt="" />
            </div>
            <div class="float_shpe2" style={{maxWidth: "5%", right: "15%", top: "75%", animation: "lighting 45s linear infinite reverse"}}>
                <img src="images/5.png" alt="" />
            </div>
            <div class="float_shpe3" style={{maxWidth: "7%"}}>
                <img src="images/7.png" alt="" />
            </div>
            <div class="float_shpe4" style={{maxWidth: "7%", right: "11%", top: "30%"}}>
                <img src="images/4.png" alt="" />
            </div>
            <div class="float_shpe4" style={{maxWidth: "4%", top: "45%", animation: "lighting 13s linear infinite reverse", left: "70%"}}>
                <img src="images/6.png" alt="" />
            </div>
            <div class="float_shpe5" style={{left: "17%", maxWidth: "4.2%", top: "40%", animation: "float 5s linear infinite"}}>
                <img src="images/5.png" alt="" />
            </div>
            <div class="float_shpe5" style={{left: "21%", maxWidth: "4.2%", top: "13%", animation: "lighting 25s linear infinite reverse"}}>
                <img src="images/7" alt="" />
            </div>
            <div class="float_shpe5" style={{maxWidth: "4.2%", top: "50%", animation: "lighting 45s linear infinite reverse"}}>
                <img src="images/4.png" alt="" />
            </div>
            <div class="float_shpe5" style={{left: "3%", maxWidth: "4.2%", top: "65%", animation: "lighting 45s linear infinite reverse"}}>
                <img src="images/7.png" alt="" />
            </div>
            <div class="float_shpe5" style={{left: "30%", maxWidth: "4.2%", animation: "lighting 45s linear infinite reverse"}}>
                <img src="images/7.png" alt="" />
            </div>            
        </>
    )
}