import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTER } from "router";

export default function Index () {
    return (
        <div>                      
            <BrowserRouter>            
                <Routes>
                    {ROUTER.map((route, i) => {
                        return <Route
                            key={i}
                            path={route.path}
                            element={route.element}
                        />    
                    })}
                </Routes>
            </BrowserRouter>
        </div>        
    )
}