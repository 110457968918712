import React, { useState, useEffect } from "react"
import { ethers } from "ethers"

import ProviderHOC from "hoc/provider";
import Header from "components/header";
import Footer from "components/footer";
import ConnectSection from "components/connectSection";

import useContracts from "hooks/useContracts";
import Yogies from "adapters/yogies";
import YogiesHouse from "adapters/yogiesHouse";
import YogiesCar from "adapters/yogiesCar";
import YogiesPets from "adapters/yogiesPets";
import YogiesStaking from "adapters/yogiesStaking";

import StakeCard from "./stakeCard";
import UnstakeCard from "./unstakeCard";
import BoostCard from "./boostCard";

import NotFound from "components/notFound";
import MobileError from "components/mobileError";
import Loader from "components/loader";
import WrongNetwork from "components/wrongNetwork";

import { ToastContainer, toast } from 'react-toastify';
import { getErrorMessage } from "utils";

import { EXTERNAL_URLS } from 'config'

function Staking({ provider }) { 

    const isConnected = !!provider?.address;

    const  [
        wrongNetwork,
        contractYogies,
        ,
        contractHouse,
        contractCar,
        contractPets,
        ,
        ,
        contractYogiesStaking,
        ,
    ] = useContracts(provider);

    const [selectedTab, setSelectedTab] = useState(0);

    const [regularYogies, setRegularYogies] = useState();
    const [stakedRegularYogies, setStakedRegularYogies] = useState();

    const [vaultYogies, setVaultYogies] = useState();
    const [stakedVaultYogies, setStakedVaultYogies] = useState();

    const [viyYogies, setViyYogies] = useState();
    const [stakedViyYogies, setStakedViyYogies] = useState();

    const [genesisYogies, setGenesisYogies] = useState();
    const [stakedGenesisYogies, setStakedGenesisYogies] = useState();

    const [regularYield, setRegularYield] = useState();
    const [vaultYield, setVaultYield] = useState();
    const [viyYield, setViyYield] = useState();
    const [genesisYield, setGenesisYield] = useState();

    const [regularType, setRegularType] = useState();
    const [vaultType, setVaultType] = useState();
    const [viyType, setViyType] = useState();
    const [genesisType, setGenesisType] = useState();

    const [cars, setCars] = useState();
    const [houses, setHouses] = useState();
    const [monke, setMonke] = useState();
    const [cats, setCats] = useState();
    const [dogs, setDogs] = useState();

    const [carBonuses, setCarBonuses] = useState();
    const [housesStaked, setHousesStaked] = useState();
    const [unstakableHouses, setUnstakableHouses] = useState()

    const [capacity, setCapacity] = useState();
    const [stakableYogies, setStakableYogies] = useState();
    const [unStakableYogies, setUnStakableYogies] = useState();

    const [mansionApprovedForAll, setMansionApprovedForAll] = useState();

    const [loaded, setLoaded] = useState();

    function isContractLoaded() {
        return provider && contractYogies && contractYogiesStaking && contractHouse && contractCar && contractPets;
    }

    async function fetchContracts() {
        let yogiesObj = new Yogies(contractYogies, provider.signer);
        let houseObj = new YogiesHouse(contractHouse, provider.signer);
        let carObj = new YogiesCar(contractCar);
        let petObj = new YogiesPets(contractPets);
        let yogiesStakingObj = new YogiesStaking(contractYogiesStaking, provider.signer)         
        
        let vaultStart = await yogiesObj.vaultStartPoint();
        let viyStart = await yogiesObj.viyStartPoint();

        let userYogies = (await yogiesObj.getYogiesOfUser(provider.address)).filter(s => !s.eq(0));
        let userGenesis = (await yogiesObj.getGYogiesOfUser(provider.address)).filter(s => !s.eq(0));
        setGenesisYogies(userGenesis)

        let userNormalYogies = vaultStart.gt(0) ? userYogies.filter(y => y.lt(vaultStart)) : userYogies.filter(y => y.lt(viyStart));
        setRegularYogies(userNormalYogies);
        
        let userVaultYogies = vaultStart.gt(0) ? userYogies.filter(y => y.gte(vaultStart) && y.lt(viyStart)) : [];
        setVaultYogies(userVaultYogies);

        let userVIYYogies = userYogies.filter(y => y.gte(viyStart));
        setViyYogies(userVIYYogies);


        let userStakedYogies = (await yogiesObj.getStakedYogiesOfUser(provider.address)).filter(s => !s.eq(0));

        let userStakedGenesis = (await yogiesObj.getStakedGYogiesOfUser(provider.address)).filter(s => !s.eq(0));
        
        setStakedGenesisYogies(userStakedGenesis);

        let userNormalStakedYogies = vaultStart.gt(0) ? userStakedYogies.filter(y => y.lt(vaultStart)) : userStakedYogies.filter(y => y.lt(viyStart));
        setStakedRegularYogies(userNormalStakedYogies);
        
        let userVaultStakedYogies = vaultStart.gt(0) ? userStakedYogies.filter(y => y.gte(vaultStart) && y.lt(viyStart)) : [];
        setStakedVaultYogies(userVaultStakedYogies);

        let userVIYStakedYogies = userStakedYogies.filter(y => y.gte(viyStart));
        setStakedViyYogies(userVIYStakedYogies);

        let houseBalance = await houseObj.balanceOf(provider.address)
        setHouses(houseBalance)

        let carBalance = await carObj.balanceOf(provider.address)
        setCars(carBalance);

        let monkeBalance = ethers.BigNumber.from(0);
        setMonke(monkeBalance);

        let catBalance = await petObj.balanceOf(provider.address)
        setCats(catBalance);

        let dogBalance = ethers.BigNumber.from(0);
        setDogs(dogBalance);

        let _regularType = yogiesStakingObj.yogieBaseType();
        let _vaultType = yogiesStakingObj.vaultYogieType();
        let _viyType = yogiesStakingObj.viyYogieType();
        let _genesisType = yogiesStakingObj.gYogieType();

        setRegularType(_regularType);
        setVaultType(_vaultType);
        setViyType(_viyType);
        setGenesisType(_genesisType);

        let _regularYield = yogiesStakingObj.yogieTypeToYield(_regularType);
        let _vaultYield = yogiesStakingObj.yogieTypeToYield(_vaultType);
        let _viyYield = yogiesStakingObj.yogieTypeToYield(_viyType);
        let _genesisYield = yogiesStakingObj.yogieTypeToYield(_genesisType);

        setRegularYield(_regularYield);
        setVaultYield(_vaultYield);
        setViyYield(_viyYield);
        setGenesisYield(_genesisYield);

        setCarBonuses(yogiesStakingObj.carBonus);

        let _housesStaked = await yogiesStakingObj.stakedHouseAmount(provider.address);
        setHousesStaked(_housesStaked);

        let _unstakableHouses = await yogiesStakingObj.getUnstakableMansions(provider.address);
        setUnstakableHouses(_unstakableHouses)

        let _capacity = _housesStaked.eq(0) ? ethers.BigNumber.from(1) : _housesStaked.mul(10);
        let _totalStaked = userStakedYogies.length + userStakedGenesis.length;

        let _capacityLeft = _capacity.sub(_totalStaked)
        if (_capacityLeft.lt(0))
            _capacityLeft = ethers.BigNumber.from(0)
        setCapacity(_capacityLeft);

        let _stakableYogies = _capacityLeft.gt(userNormalYogies.length) ? ethers.BigNumber.from(userNormalYogies.length) : _capacityLeft;
        setStakableYogies(_stakableYogies);

        let _unstakableYogies = (userNormalYogies.length === 0 || _stakableYogies.eq(0)) ? ethers.BigNumber.from(userNormalStakedYogies.length) : ethers.BigNumber.from(0);
        setUnStakableYogies(_unstakableYogies);

        let _mansionApprovedForAll = await houseObj.isApprovedForAll(provider.address, contractYogiesStaking.address);
        setMansionApprovedForAll(_mansionApprovedForAll);

        setLoaded(true);
    }

    useEffect(() => {
        if (isContractLoaded()) {
            fetchContracts();
          provider.instance.on("block", (_) => {
            fetchContracts();
          });
          return () => provider.instance.off("block");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractYogies, contractYogiesStaking, contractHouse, contractCar, contractPets]);

    const [isTx, setIsTx] = useState();
    const [txHash, setTxHash] = useState();

    const [txError, setTxError] = useState();
    const [txSuccess, setTxSuccess] = useState();

    useEffect(() => {
        if (txError) {
            toast.error(txError, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }          
    }, [txError])

    useEffect(() => {
        if (txSuccess) {
            toast.success(txSuccess, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }, [txSuccess])


    async function onStakeAll() {
        if (!isTx && contractYogiesStaking) {
            setIsTx();
            setTxHash();
            setTxError();
            setTxSuccess();
            
            const yogiesStakingObj = new YogiesStaking(contractYogiesStaking, provider.signer);

            try {               
                let tx = await yogiesStakingObj.stakeManyYogies(regularYogies.slice(0, stakableYogies.toNumber()), new Array(stakableYogies.toNumber()).fill(regularType))
                setIsTx(true);
                setTxHash(tx.hash);
                await tx.wait();
                setTxSuccess(`Yogies were successfully staked`);
            } catch (e) {
                setTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setIsTx();
            setTxHash();
        }
    }

    async function onUnStakeAll() {
        if (!isTx && contractYogiesStaking) {
            setIsTx();
            setTxHash();
            setTxError();
            setTxSuccess();
            
            const yogiesStakingObj = new YogiesStaking(contractYogiesStaking, provider.signer);

            try {                
                let tx = await yogiesStakingObj.unStakeManyYogies(stakedRegularYogies.slice(0, unStakableYogies.toNumber()), new Array(unStakableYogies.toNumber()).fill(regularType))
                setIsTx(true);
                setTxHash(tx.hash);
                await tx.wait();
                setTxSuccess(`Yogies were successfully unstaked`);
            } catch (e) {
                setTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setIsTx();
            setTxHash();
        }
    }

    return (
        <>
            <MobileError />
            
            <div className="notsupport">
                {
                    isConnected && !wrongNetwork &&
                    <Header 
                        active={1}
                    />
                }

                {
                    isConnected && !wrongNetwork ? 
                        <div className="box_dash">                         
                            <div className="w3-container">
                                <div className="w3-row">
                                    <span onClick={() => setSelectedTab(0)}>
                                        <div className={`w3-third tablink w3-bottombar w3-hover-light-grey w3-padding ${selectedTab === 0 ? "w3-border-red" : null}`}>Regular Yogies ({regularYogies && stakedRegularYogies ? regularYogies.length + stakedRegularYogies.length : 0})</div>
                                    </span>
                                    <span onClick={() => setSelectedTab(1)}>
                                        <div className={`w3-third tablink w3-bottombar w3-hover-light-grey w3-padding ${selectedTab === 1 ? "w3-border-red" : null}`}>Boosts ({houses && cars && monke && cats && dogs && housesStaked ? houses.add(housesStaked).add(cars).add(monke).add(cats).add(dogs).toString() : 0})</div>
                                    </span>
                                    <span onClick={() => setSelectedTab(2)}>
                                        <div className={`w3-third tablink w3-bottombar w3-hover-light-grey w3-padding ${selectedTab === 2 ? "w3-border-red" : null}`}>Vault ({vaultYogies && stakedVaultYogies && viyYogies && stakedViyYogies && genesisYogies && stakedGenesisYogies ? vaultYogies.length + stakedVaultYogies.length + viyYogies.length + stakedViyYogies.length + genesisYogies.length + stakedGenesisYogies.length : 0})</div>
                                    </span>
                                </div>

                                <Loader text={"Loading your Yogies"} show={!loaded} />

                                {
                                    selectedTab === 0 && loaded &&
                                    <>
                                        <div id="Regular Yogies" className="w3-container Yogie">
                                            {
                                                regularYogies && regularYogies.length ? regularYogies.map(y => 
                                                    <StakeCard
                                                        provider={provider}
                                                        yogieId={y}
                                                        isGenesis={false}
                                                        yogieYield={regularYield}
                                                        yogieType={regularType}
                                                        key={y.toNumber()}
                                                        fetchContracts={fetchContracts}
                                                        capacity={capacity}
                                                    />
                                                ) : null
                                            }

                                            {
                                                stakedRegularYogies && stakedRegularYogies.length ? stakedRegularYogies.map(y => 
                                                    <UnstakeCard
                                                        provider={provider}
                                                        yogieId={y}
                                                        isGenesis={false}
                                                        yogieYield={regularYield}
                                                        yogieType={regularType}
                                                        key={y.toNumber()}
                                                        fetchContracts={fetchContracts}                                                
                                                    />
                                                ) : null
                                            }

                                            {
                                                (!regularYogies || regularYogies.length === 0) && (!stakedRegularYogies || stakedRegularYogies.length === 0) &&
                                                    <NotFound item={"Yogie"} /> 
                                            }
                                        </div>

                                        <div className="balancebox2"></div>

                                        {
                                            stakableYogies.gt(0) && regularYogies.length > 0 && !isTx &&
                                            <>                                                
                                                <div className="balancebox">
                                                    <div className="balance" style={{cursor: "pointer", height: "50px"}} onClick={onStakeAll}>STAKE {stakableYogies.toString()} YOGIES</div>
                                                </div>
                                            </>
                                        }

                                        {
                                            unStakableYogies.gt(0) && (regularYogies.length === 0 || stakableYogies.eq(0)) && !isTx &&
                                            <>                                                
                                                <div className="balancebox">
                                                    <div className="balance" style={{cursor: "pointer", height: "50px"}} onClick={onUnStakeAll}>UNSTAKE {unStakableYogies.toString()} YOGIES</div>
                                                </div>
                                            </>
                                        }

                                        {
                                            isTx &&
                                            <div className="balancebox">
                                                <div className="balance" style={{cursor: "pointer", height: "50px"}} onClick={() => window.open(EXTERNAL_URLS.etherscan + txHash, "_blank")}>VIEW TRANSACTION</div>
                                            </div>
                                        }

                                        <ToastContainer
                                            position="top-right"
                                            autoClose={8000}
                                            hideProgressBar={true}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                        />
                                    </>                                    
                                }

                                {
                                    selectedTab === 1 && loaded &&
                                    <div id="Boosts" className="w3-container Yogie">
                                        {
                                            houses && housesStaked && houses.add(housesStaked).gt(0) ?
                                            <BoostCard
                                                provider={provider}
                                                itemType={0}
                                                itemAmount={houses}
                                                houseAmount={houses}
                                                stakedHouses={housesStaked}
                                                unstakableHouses={unstakableHouses}
                                                fetchContracts={fetchContracts}
                                                mansionApprovedForAll={mansionApprovedForAll}
                                                key={0}
                                            /> : null
                                        }
                                        {
                                            cars && cars.gt(0) ?
                                            <BoostCard
                                                provider={provider}
                                                itemType={1}
                                                itemAmount={cars}
                                                houseAmount={houses}
                                                stakedHouses={housesStaked}
                                                fetchContracts={fetchContracts}
                                                mansionApprovedForAll={mansionApprovedForAll}
                                                key={1}                                            
                                            /> : null
                                        }
                                        {
                                            monke && monke.gt(0) ?
                                            <BoostCard
                                                provider={provider}
                                                itemType={2}
                                                itemAmount={monke}
                                                houseAmount={houses}
                                                stakedHouses={housesStaked}
                                                fetchContracts={fetchContracts}
                                                mansionApprovedForAll={mansionApprovedForAll}
                                                key={2}                                           
                                            /> : null
                                        }
                                        {
                                            cats && cats.gt(0) ?
                                            <BoostCard
                                                provider={provider}
                                                itemType={3}
                                                itemAmount={cats}
                                                houseAmount={houses}
                                                stakedHouses={housesStaked}
                                                fetchContracts={fetchContracts}
                                                mansionApprovedForAll={mansionApprovedForAll}
                                                key={3}                                          
                                            /> : null
                                        }
                                        {
                                            dogs && dogs.gt(0) ?
                                            <BoostCard
                                                provider={provider}
                                                itemType={4}
                                                itemAmount={dogs}
                                                houseAmount={houses}
                                                stakedHouses={housesStaked}
                                                fetchContracts={fetchContracts}
                                                mansionApprovedForAll={mansionApprovedForAll}
                                                key={4}                                
                                            /> : null
                                        }

                                        {
                                            (!houses || houses.eq(0)) && (!housesStaked || housesStaked.eq(0)) && (!cars || cars.eq(0)) && (!monke || monke.eq(0)) && (!cats || cats.eq(0)) && (!dogs || dogs.eq(0)) && 
                                                <NotFound item={"boost"} /> 
                                        }
                                    </div>
                                }

                                {
                                    selectedTab === 2 && loaded &&
                                    <div id="Vault" className="w3-container Yogie">
                                        {
                                            genesisYogies && genesisYogies.length ? genesisYogies.map(y => 
                                                <StakeCard
                                                    provider={provider}
                                                    yogieId={y}
                                                    isGenesis={true}
                                                    yogieYield={genesisYield}
                                                    yogieType={genesisType}
                                                    key={y.toNumber()}
                                                    fetchContracts={fetchContracts}
                                                    capacity={capacity}
                                                />
                                            ) : null
                                        }

                                        {
                                            stakedGenesisYogies && stakedGenesisYogies.length ? stakedGenesisYogies.map(y => 
                                                <UnstakeCard
                                                    provider={provider}
                                                    yogieId={y}
                                                    isGenesis={true}
                                                    yogieYield={genesisYield}
                                                    yogieType={genesisType}
                                                    key={y.toNumber()}
                                                    fetchContracts={fetchContracts}
                                                />
                                            ) : null
                                        }

                                        {
                                            viyYogies && viyYogies.length ? viyYogies.map(y => 
                                                <StakeCard
                                                    provider={provider}
                                                    yogieId={y}
                                                    isGenesis={false}
                                                    yogieYield={viyYield}
                                                    yogieType={viyType}
                                                    key={y.toNumber()}
                                                    fetchContracts={fetchContracts}
                                                    capacity={capacity}
                                                />
                                            ) : null
                                        }

                                        {
                                            stakedViyYogies && stakedViyYogies.length ? stakedViyYogies.map(y => 
                                                <UnstakeCard
                                                    provider={provider}
                                                    yogieId={y}
                                                    isGenesis={false}
                                                    yogieYield={viyYield}
                                                    yogieType={viyType}
                                                    key={y.toNumber()}
                                                    fetchContracts={fetchContracts}
                                                />
                                            ) : null
                                        }

                                        {
                                            vaultYogies && vaultYogies.length ? vaultYogies.map(y => 
                                                <StakeCard
                                                    provider={provider}
                                                    yogieId={y}
                                                    isGenesis={false}
                                                    yogieYield={vaultYield}
                                                    yogieType={vaultType}
                                                    key={y.toNumber()}
                                                    fetchContracts={fetchContracts}
                                                    capacity={capacity}
                                                />
                                            ) : null
                                        }

                                        {
                                            stakedVaultYogies && stakedVaultYogies.length ? stakedVaultYogies.map(y => 
                                                <UnstakeCard
                                                    provider={provider}
                                                    yogieId={y}
                                                    isGenesis={false}
                                                    yogieYield={vaultYield}
                                                    yogieType={vaultType}
                                                    key={y.toNumber()}
                                                    fetchContracts={fetchContracts}
                                                />
                                            ) : null
                                        }

                                        {
                                            (!genesisYogies || genesisYogies.length === 0) && (!stakedGenesisYogies || stakedGenesisYogies.length === 0) &&
                                            (!viyYogies || viyYogies.length === 0) && (!stakedViyYogies || stakedViyYogies.length === 0) &&
                                            (!vaultYogies || vaultYogies.length === 0) && (!stakedVaultYogies || stakedVaultYogies.length === 0) &&
                                                <NotFound item={"Vault Yogie"} /> 
                                        }
                                    </div>
                                }                                                                                
                            </div>
                            <Footer /> 
                        </div>
                    :
                    wrongNetwork ?
                    <>
                        <WrongNetwork current={wrongNetwork} provider={provider} />
                        <Footer /> 
                    </>
                    :
                    <>
                        <ConnectSection />
                        <Footer />                    
                    </>   
                }
            </div>
            
        </>
    )
}

export default ProviderHOC(Staking)