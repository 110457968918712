import React from "react"

export default function NotFound({ item }) {
    return (
        <>
            <div class="box_staking2">
                <img src="/images/error.png" style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%", marginBottom: "-5%"}}/>
                <div style={{justifyContent: "center", textAlign: "center", fontSize: "25px"}}>Couldn't find a {item} </div>
                <div style={{fontSize: "12px", justifyContent: "center", textAlign: "center", color: "#919191"}}>
                    We couldn't find any {item} in your connected<br/>walllet. Don't worry you can still get yourself a {item}<br/>from the secondary marketplace.
                </div>                                
            </div>
        </>
    )
}