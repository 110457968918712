export default class Gemies {
    constructor(instance, signer) {
        this.instance = instance.connect(signer);
    }

    depositInEcosystem(amount) {
        return this.instance.depositInEcosystem(amount);
    }

    withdrawFromEcosystem(amount) {
        return this.instance.withdrawFromEcosystem(amount);
    }

    transferEcosystemBalance(amount) {
        return this.instance.transferEcosystemBalance(amount);
    }

    getEcoSystemBalance(address) {
        return this.instance.getEcoSystemBalance(address);
    }

    balanceOf(address) {
        return this.instance.balanceOf(address);
    }
}