import React from "react"
import { Oval } from  'react-loader-spinner'

export default function Loader({ text, show }) {
    return (
        <>
           {
               show &&
               <>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "25px", padding: "50px", flexDirection: "column"}}>
                            <Oval
                                height="100"
                                width="100"
                                color='#ffc520'
                                ariaLabel='loading'                                        
                            />                                        
                        <div style={{display: "block", marginTop: "5px"}}>{text}</div>
                    </div>  
                </>
           }
        </>
    )
}