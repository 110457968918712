import React from "react"

export default function MobileError() {
    return (
        <>
            <div class="errorsupport">       
                <div class="box_staking2" style={{
                    maxWidth: "100%", 
                    margin: "0", 
                    position: "absolute", 
                    top: "50%", 
                    msTransform: "translateY(-50%)", 
                    transform: "translateY(-50%)"
                }}>
                <img src="/images/error2.png" style={{
                        display: "block", 
                        marginLeft: "auto", 
                        marginRight: "auto", 
                        width: "50%", 
                        marginBottom: "-5%"
                    }}/>
                    <div style={{justifyContent: "center", textAlign: "center", fontSize: "25px"}}>Woops, access denied</div>
                    <div style={{fontSize: "12px", justifyContent: "center", textAlign: "center", color: "#919191"}}>
                        Please use a desktop to access our dashboard.<br/>Mobile is not supported.
                    </div>           
                </div>
            </div>
        </>
    )
}