import React, { useState, useEffect } from 'react'
import { EXTERNAL_URLS } from 'config'

import useContracts from "hooks/useContracts";
import YogiesStaking from "adapters/yogiesStaking";

import { formatNumber, getErrorMessage } from "utils";
import { ToastContainer, toast } from 'react-toastify';

export default function StakeCard({ 
    provider,
    yogieId, 
    isGenesis, 
    yogieYield,
    yogieType,
    fetchContracts,
    capacity
}) {
    const imageUrl = isGenesis ? 
        EXTERNAL_URLS.yogie_genesis_base_url + yogieId.toString() + ".png" :
        yogieType === 1 ?
        EXTERNAL_URLS.vault_image_base_url + yogieId.toString() + ".png"
        :
        yogieType === 2 ?
        EXTERNAL_URLS.viy_image_base_url + yogieId.toString() + ".png"
        :
        EXTERNAL_URLS.yogie_image_base_url + yogieId.toString() + ".png"

    const [isTx, setIsTx] = useState();
    const [txHash, setTxHash] = useState();

    const [txError, setTxError] = useState();
    const [txSuccess, setTxSuccess] = useState();

    useEffect(() => {
        if (txError) {
            toast.error(txError, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }          
    }, [txError])

    useEffect(() => {
        if (txSuccess) {
            toast.success(txSuccess, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }, [txSuccess])

    const  [
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        contractYogiesStaking,
        ,
    ] = useContracts(provider);

    async function onStake() {
        if (!isTx && contractYogiesStaking) {
            setIsTx();
            setTxHash();
            setTxError();
            setTxSuccess();
            
            if (capacity && capacity.eq(0)) {
                setTxError("You do not have enough staking slots. Please purchase a mansion from our item shop.");
                return;
            }
            
            const yogiesStakingObj = new YogiesStaking(contractYogiesStaking, provider.signer);

            try {
                setIsTx(true);
                let tx = await yogiesStakingObj.stakeSingleYogie(yogieId, yogieType);
                setTxHash(tx.hash);
                await tx.wait();
                setTxSuccess(`Yogie ${yogieId} was successfully staked.`)
            } catch (e) {
                setTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setIsTx();
            setTxHash();
        }
    }

    return (
        <>
            <div className="box_staking">
                <img src={imageUrl} style={{borderRadius: "20px"}} alt={`yogie #${yogieId.toString()}`}/>
                <div className="yogienumber">#{yogieId.toString()}
                    <div className="earning2">
                        <div className="earning">{yogieYield ? formatNumber(yogieYield.toString()) : 0} DAILY</div>
                    </div>
                </div>

                {
                    !isTx &&
                    <div className="button_stake" onClick={onStake} style={{cursor: "pointer"}}>STAKE</div>
                }

                {
                    isTx && !txError &&
                    <div id="tx">
                        {
                            txHash ?
                            <div className="button_stake" onClick={() => window.open(EXTERNAL_URLS.etherscan + txHash, "_blank")}>VIEW TRANSACTION</div>
                            :
                                <div style={{color: "white", textAlign: "center", fontSize: "12px", fontFamily: 'Russo One, sans-serif'}}>Please confirm the transaction in your wallet.</div>
                        }
                    </div>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={8000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </>
    )
}