import React, { useState, useEffect } from "react"
import { ethers } from "ethers"

import {
    collection,
    getDoc,
    doc,
    getDocs,
    query,
    orderBy,
    limit,
    startAt,
    where
} from 'firebase/firestore';

import ProviderHOC from "hoc/provider";
import Header from "components/header";
import Footer from "components/footer";
import ConnectSection from "components/connectSection";

import MobileError from "components/mobileError";
import LeaderboardRow from "components/leaderboardRow";
import AttackPopup from "components/attackPopup";

import YogiesAttack from "adapters/yogiesAttack";
import YogiesPets from "adapters/yogiesPets";
import Yogies from "adapters/yogies";
import YogiesHouse from "adapters/yogiesHouse";
import YogiesStaking from "adapters/yogiesStaking";
import useContracts from "hooks/useContracts";

import { getFireBase } from "hooks/useFirebase";
import useClock from "hooks/useClock";
import { getArgsFromEvent, getTruncatedAddress, getUnixTimeStamp, formatSeconds } from "utils";

import { ToastContainer, toast } from 'react-toastify';
import Loader from "components/loader";

const [firestore,] = getFireBase();

const PAGE_SIZE = 3;

function Notification({ provider }) {
    const isConnected = !!provider?.address;
    
    const  [
        wrongNetwork,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        contractYogiesStaking,
        contractYogiesAttack,
    ] = useContracts(provider);

    const [dailyEarnings, setDailyEarnings] = useState();

    const [userData, setUserData] = useState();
    const [loaded, setLoaded] = useState();

    const [now, setNow] = useState(getUnixTimeStamp());
    useClock(() => {
        setNow(_ => getUnixTimeStamp())
    });

    async function fetchHistory() {
        if (firestore && provider && provider.address) {
            const docRef = doc(firestore, "leaderboard_entries", provider.address.toLowerCase());
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                let data = docSnap.data();
                data.history.sort((a,b) => {
                    return b.block - a.block
                })
                data.history = data.history.map(h => ({
                    ...h,
                    stolen: ethers.BigNumber.from(h.stolen)
                }))
                setUserData(data)
            } else {
                setUserData({})
            }

            setLoaded(true)
        }
    }

    async function fetchUserData() {
        let yogiesStaking = new YogiesStaking(contractYogiesStaking, provider.signer)
        
        let _dailyEarnings = await yogiesStaking.getDailyReward(provider.address)
        setDailyEarnings(_dailyEarnings)

        //setLoaded(true)
    }

    useEffect(() => {
        if (provider && provider.address && contractYogiesAttack && contractYogiesStaking) {
            fetchUserData()
            provider.instance.on("block", () => {
                fetchUserData()
            })
        }
    }, [provider, contractYogiesAttack, contractYogiesStaking])

    useEffect(() => {
    if (!userData)
            fetchHistory()
    }, [firestore, provider])

    return (
        <>
           <MobileError />
            <div className="notsupport">

                {
                    isConnected &&
                    <Header 
                        active={-1}
                    />
                }

                {
                    isConnected ? 
                    <div className="box_dash">
                          <Loader text={"Loading your attack history"} show={!loaded} />
                                 
                            {
                                loaded && Object.keys(userData).length > 0 ?
                                <>
                                     <div class="box_notif">
                                        <div class="box_noti"> 
                                            <div class="box_ntc">
                                                <text>#ATTACK</text>
                                                <text>ADDRESS</text>
                                                <text>DAMAGE</text>
                                            </div> 
                                            {
                                                userData.history.slice(0,4).map((h,i) => {
                                                    let { attacker, stolen } = h
                                                    attacker = getTruncatedAddress(attacker)
                                                    stolen = ethers.utils.formatEther(stolen);

                                                    let style1 = {}
                                                    let style2 = {}

                                                    switch(i) {                                                        
                                                        case 1:
                                                            style1.backgroundColor = "#6a665abf"
                                                            style1.border = "2px solid #abababba"
                                                            style1.color = "#020c1cc7"

                                                            style2.backgroundColor = "#abababb8"                                                            
                                                            break;
                                                        case 2:
                                                            style1.backgroundColor = "#6a665a8a"
                                                            style1.border = "2px solid #ababab8a"
                                                            style1.color = "#020c1c94"

                                                            style2.backgroundColor = "#ababab91" 
                                                            break;
                                                        case 3:
                                                            style1.backgroundColor = "#6a665a63"
                                                            style1.border = "2px solid #ababab5e"
                                                            style1.color = "#020c1c54"

                                                            style2.backgroundColor = "#ababab54" 
                                                            break;
                                                        default:
                                                            break;
                                                    }

                                                    return <div class="box_ntc2" style={style1}>
                                                            <div class="box_lb4" style={style2}>{i + 1}</div>
                                                            <text>{attacker}</text>
                                                            <text>{stolen} $GEMIES</text>
                                                        </div>
                                                })
                                            }

                                            {
                                                 userData.history.length < 4 ?
                                                    <>
                                                        {
                                                            (new Array(4).fill(0)).map((h,i) => {
                                                                if (i < userData.history.length)
                                                                    return null;
                                                                    
                                                                let style1 = {}
                                                                let style2 = {}
            
                                                                switch(i) {                                                        
                                                                    case 1:
                                                                        style1.backgroundColor = "#6a665abf"
                                                                        style1.border = "2px solid #abababba"
                                                                        style1.color = "#020c1cc7"
            
                                                                        style2.backgroundColor = "#abababb8"                                                            
                                                                        break;
                                                                    case 2:
                                                                        style1.backgroundColor = "#6a665a8a"
                                                                        style1.border = "2px solid #ababab8a"
                                                                        style1.color = "#020c1c94"
            
                                                                        style2.backgroundColor = "#ababab91" 
                                                                        break;
                                                                    case 3:
                                                                        style1.backgroundColor = "#6a665a63"
                                                                        style1.border = "2px solid #ababab5e"
                                                                        style1.color = "#020c1c54"
            
                                                                        style2.backgroundColor = "#ababab54" 
                                                                        break;
                                                                    default:
                                                                        break;
                                                                }
            
                                                                return <div class="box_ntc2" style={style1}>
                                                                        <div class="box_lb4" style={style2}>{i + 1}</div>
                                                                        <text>No data found</text>
                                                                        <text></text>
                                                                    </div>
                                                            })
                                                        }                                                   
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div> 

                                        <div class="box_right">
                                            <div class="box_inside">INFORMATION</div>
                                            {
                                                userData.cooldown ?
                                                    <>
                                                        <div class="box_inside2">CURRENT COOLDOWN</div>
                                                        <div class="box_inside3">{formatSeconds(Math.max(userData.cooldown - now, 0))}</div>
                                                        <div class="box_inside4"></div>
                                                    </>
                                                :
                                                    <>
                                                        <div class="box_inside2">CURRENT COOLDOWN</div>
                                                        <div class="box_inside3">0</div>
                                                        <div class="box_inside4"></div>
                                                    </>
                                            }
                                            
                                            {
                                                userData.history.length > 0 ?
                                                    <>
                                                        <div class="box_inside2">MOST RECENT DAMAGE TAKEN</div>
                                                        <div class="box_inside3">{ethers.utils.formatEther(userData.history[0].stolen)} $GEMIES</div>
                                                        <div class="box_inside4"></div>
                                                        <div class="box_inside2">ADDRESS OF MOST RECENT ATTACKER</div>
                                                        <div class="box_inside3">{getTruncatedAddress(userData.history[0].attacker)}</div>
                                                        <div class="box_inside4"></div>
                                                    </>
                                                :
                                                    <>
                                                        <div class="box_inside2">MOST RECENT DAMAGE TAKEN</div>
                                                        <div class="box_inside3">0 $GEMIES</div>
                                                        <div class="box_inside4"></div>
                                                        <div class="box_inside2">ADDRESS OF MOST RECENT ATTACKER</div>
                                                        <div class="box_inside3">0x0</div>
                                                        <div class="box_inside4"></div>
                                                    </>
                                            }
                                            
                                            <div class="box_inside2">YOUR TOTAL DAILY YIELD</div>
                                            <div class="box_inside3">{dailyEarnings ? ethers.utils.formatEther(dailyEarnings) : 0} $GEMIES</div>
                                        </div>
                                    </div>
                                </>
                                :
                                loaded && Object.keys(userData).length === 0 ?
                                    <>
                                        <div class="box_staking2">
                                            <img src="/images/error.png" style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%", marginBottom: "-5%"}}/>
                                            <div style={{justifyContent: "center", textAlign: "center", fontSize: "25px"}}>No history </div>
                                            <div style={{fontSize: "12px", justifyContent: "center", textAlign: "center", color: "#919191"}}>
                                                Your history will appear once someone attacks you.
                                            </div>                                
                                        </div>
                                    </>
                                :
                                    null
                            }
                        <Footer />                                 
                        
                    </div>
                    :
                    <>
                        <ConnectSection />
                        <Footer />                    
                    </> 
                }                    
            </div>
        </>
    )
}

export default ProviderHOC(Notification)