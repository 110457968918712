import React, { memo, useEffect } from "react"
import Footer from "components/footer"

import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";

import FloatingObjects from "components/floatingObjects";

const Connectsection = memo(
    ({ provider, setProvider }) => {
        const isConnected = !!provider?.address;
        const [, isConnecting, doConnect,, connection] = useConnect(provider);
    
        useEffect(() => {
          setProvider(connection);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [connection]);
        
        return (
            <>
                {
                    !isConnected &&
                    <>
                        <FloatingObjects/>
                        <img src="/images/logo2.png" style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50px", marginTop: "5%"}}/>
                        <div class="box3_mint" style={{height: "40px"}}></div>
                        <div class="box_connect" style={{backgroundColor: "#061630", padding: "30px", marginLeft: "auto", marginRight: "auto", width: "400px", position: "sticky"}}>  
                            <div class="connect" style={{verticalAlign: "middle", fontSize: "16px", fontFamily: 'Russo One, sans-serif', textAlign: "center"}}>
                                Welcome to YogieLand
                            </div>
                            <div class="connect2" style={{textAlign: "center", fontSize: "11px", fontFamily: "sans-serif", color: "#ffffffa3"}}>
                                Please connect your wallet to get access to a bright, fun world. We cannot wait to welcome you! Get access to your Profile, Staking, Item Shop, Leaderboard & $Gemies exchange.
                            </div>
                        </div>              
                        <div class="box2_mint"> 
                            <div class="button_mint" onClick={doConnect}>CONNECT</div>
                        </div>
                    </>
                }
            </>
        )
    }
)

export default ProviderHOC(Connectsection);