import React, { useState, useEffect } from "react"
import { ethers } from "ethers"

import {
    collection,
    getDocs,
    query,
    orderBy,
    limit,
    startAt,
    where
} from 'firebase/firestore';

import ProviderHOC from "hoc/provider";
import Header from "components/header";
import Footer from "components/footer";
import ConnectSection from "components/connectSection";

import MobileError from "components/mobileError";
import LeaderboardRow from "components/leaderboardRow";
import AttackPopup from "components/attackPopup";

import YogiesAttack from "adapters/yogiesAttack";
import YogiesPets from "adapters/yogiesPets";
import Yogies from "adapters/yogies";
import YogiesHouse from "adapters/yogiesHouse";
import YogiesStaking from "adapters/yogiesStaking";
import useContracts from "hooks/useContracts";

import { getFireBase } from "hooks/useFirebase";
import useClock from "hooks/useClock";
import { getArgsFromEvent, getUnixTimeStamp } from "utils";

import { ToastContainer, toast } from 'react-toastify';
import Loader from "components/loader";

const [firestore,] = getFireBase();

const PAGE_SIZE = 10;

function Leaderboard({ provider }) {
    const isConnected = !!provider?.address;
    
    const  [
        wrongNetwork,
        contractYogies,
        ,
        contractHouse,
        ,
        contractPets,
        ,
        ,
        contractYogiesStaking,
        contractYogiesAttack,
    ] = useContracts(provider);

    const [currentPage, setCurrentPage] = useState(0);
    const [allLbItems, setAllLbItems] = useState([]);
    const [lbItems, setLbItems] = useState([]);

    const [attackPopupVisible, setAttackPopupVisible] = useState(false);
    const [victim, setVictim] = useState();
    const [damage, setDamage] = useState();
    const [stolen, setStolen] = useState();
    const [result, setResult] = useState();

    const [providedYogie, setProvidedYogie] = useState(0);
    const [hasYogie, setHasYogie] = useState();
    const [cooldown, setCooldown] = useState();

    const [walletDataLoaded, setWalletDataLoaded] = useState();
    const [lbLoaded, setLbLoaded] = useState();

    const [isTx, setIsTx] = useState();
    const [txError, setTxError] = useState();
    const [txSuccess, setTxSuccess] = useState();

    const [now, setNow] = useState(getUnixTimeStamp());

    useClock(() => {
        setNow(_ => getUnixTimeStamp())
    });

    useEffect(() => {
        if (txError) {
            toast.error(txError, {
                position: "top-right",
                autoClose: 16000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }          
    }, [txError])

    const attackUser = async (address) => {
        if (isTx)
            return;

        if (!lbLoaded || !walletDataLoaded) {
            setTxError("Data not loaded. Please wait or refresh the page if it takes longer than 5 minutes.")
            return;
        }

        let yogiesAttack = new YogiesAttack(contractYogiesAttack, provider.signer);
        let petObj = new YogiesPets(contractPets);
        //let yogiesObj = new Yogies(contractYogies, provider.signer);
        //let houseObj = new YogiesHouse(contractHouse, provider.signer)
        let stakingObj = new YogiesStaking(contractYogiesStaking, provider.signer)

        setIsTx(true);
        setTxError();
        setTxSuccess();

        if (address.toLowerCase() === provider.address.toLowerCase()) {
            setTxError("You cannot attack yourself.")
            return;
        }
               
        if (!hasYogie) {
            setTxError("You don't own any yogies and therefore you cannot attack.")
            return;
        }
                
        if (cooldown.gt(now)) {
            setTxError("You cannot attack as you are still in cooldown. Please check the profile tab to see when your cooldown ends.")
            return;
        }
       
        let hasPet = await petObj.balanceOf(address)
        hasPet = hasPet.gt(0);
       
        if (hasPet) {
            setTxError("Victim is protected by pet. You cannot attack them.")
            return;
        }
       
        //let hasVictimHouse = await houseObj.balanceOf(address)
        //if (hasVictimHouse.gt(0)) {
        //    let userHouse = await houseObj.balanceOf(provider.address)
        //    if (userHouse.eq(0)) {
        //        setTxError("You don't own any houses and the victim does. Therefore you cannot attack them. If you have houses staked, please unstake them and try again.");
        //        return;
        //    }
        //}
       
        let hasVictimEarnings = await stakingObj.getDailyReward(address);
        if (hasVictimEarnings.eq(0)) {
            setTxError("Victim does not earn any Gemies so you can not steal anything from them.")
            return;
        }
       
        try {
            let tx = await yogiesAttack.attack(address, providedYogie);
            setVictim(address)
            setAttackPopupVisible(true)
            let result = await tx.wait();
            let attackEvent = getArgsFromEvent(contractYogiesAttack, result, "Attack")
            
            if (attackEvent && attackEvent.length > 0) {
                let event = attackEvent[0];
                if (event && event.length > 0) {
                    let { percentage, stolen } = event;
                    setDamage(percentage.toString())
                    setStolen(ethers.utils.formatEther(stolen).toString())
                    setResult("success");

                    let cooldownOfVictim = Math.floor(Date.now() / 1000) + 3600 * percentage.toNumber();
                    setLbItems(_ => {
                        let victimObj = _.find(d => d.wallet === address)
                        let victimIdx = _.indexOf(victimObj)
                        victimObj.cooldown = cooldownOfVictim;
                        _[victimIdx] = victimObj
                        return _;
                    })
                }
            }            
        } catch (e) {
            setVictim(address)
            setDamage(0)
            setStolen(0)
            setAttackPopupVisible(true)
            setResult("failed");
        }

        await fetchUserData();
        setIsTx(false);
    }

    async function fetchLeaderboard() {
        if (firestore) {
            setLbLoaded(false)           
            let q = query(collection(firestore, "leaderboard_entries"), orderBy("netEarnings", "desc"), where("netEarnings", ">", 0));

            let querySnapShot = await getDocs(q)            
            let _walletData = querySnapShot.docs.map((doc, i) => {
                return {
                    ...doc.data(),
                    
                }
            })

            _walletData.sort((a,b) => b.netEarnings - a.netEarnings)
            _walletData = _walletData.map((w, i) => {
                return {
                    ...w,
                    rank: i + 1                
                }
            })

            console.log("")
            console.log("FETCH")
            console.log(_walletData.length)
            console.log(_walletData[0])
            console.log(_walletData[_walletData.length - 1])

            setAllLbItems(_walletData)
            setLbItems(_walletData.slice(0, PAGE_SIZE))
            setLbLoaded(true)
        }
    }

    async function fetchUserData() {
        let yogiesAttack = new YogiesAttack(contractYogiesAttack, provider.signer);       
        let yogiesObj = new Yogies(contractYogies, provider.signer);

        let yogiesBalance = await yogiesObj.balanceOf(provider.address);
        setHasYogie(yogiesBalance.gt(0))
        
        let cooldown = await yogiesAttack.userToAttackCooldown(provider.address)
        setCooldown(cooldown);

        let userYogies = await yogiesObj.getYogiesOfUser(provider.address)
        let stakedYogies = await yogiesObj.getStakedGYogiesOfUser(provider.address);

        let allYogies = userYogies.concat(stakedYogies).map(y => y.toNumber());
        let viy = allYogies.filter(y => y >= 4334);

        if (viy.length > 0)
            setProvidedYogie(viy[0])
        else {
            let vault = allYogies.filter(y => y >= 3334)
            if (vault.length > 0) 
                setProvidedYogie(vault[0])
        }

        setWalletDataLoaded(true)
    }

    function setPage() {
        let items = allLbItems.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);
        setLbItems(items);
    }

    useEffect(() => {
        if (provider && provider.address && contractYogiesAttack && contractYogies)
            fetchUserData()
    }, [provider, contractYogiesAttack, contractYogies])

    useEffect(() => {
        if (allLbItems.length === 0)
            fetchLeaderboard()
    }, [firestore])

    useEffect(() => {
        setPage();
    }, [currentPage])

    return (
        <>
           <MobileError />
            <div className="notsupport">

                {
                    isConnected &&
                    <Header 
                        active={3}
                    />
                }

                {
                    isConnected ? 
                    <div className="box_dash">
                          <Loader text={"Loading leaderboard"} show={!lbLoaded || !walletDataLoaded} />

                            {
                                lbLoaded && walletDataLoaded ?
                                <>
                                    <div class="box_lbc">
                                        <text>RANK</text><text>ADDRESS</text><text>DAILY YIELD</text>{/*<text>HOLDINGS</text>*/}<text>STATUS</text>
                                    </div>
                                    {
                                        lbItems.map(item => {
                                            return <LeaderboardRow 
                                                    rank={item.rank}
                                                    address={item.wallet}
                                                    gpd={item.netEarnings}
                                                    cooldownLeft={item.cooldown}
                                                    attackUser={attackUser}
                                                    now={now}
                                                    petsBalance={item.petsBalance}
                                                />
                                        })
                                    }

                                    {
                                        lbItems.length === 0 ? 
                                        <div class="box_staking2">
                                            <img src="/images/error.png" style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%", marginBottom: "-5%"}}/>
                                            <div style={{justifyContent: "center", textAlign: "center", fontSize: "25px"}}>No users found </div>
                                            <div style={{fontSize: "12px", justifyContent: "center", textAlign: "center", color: "#919191"}}>
                                                Please press the previous button until you find some users.
                                            </div>                                
                                        </div>
                                        :
                                            null
                                    }

                                    <div className="balancebox">
                                        <div 
                                            className="balance" 
                                            style={{cursor: "pointer", width: "30px"}} 
                                            onClick={() => setCurrentPage(_ => _ > 0 ? _ - 1 : 0)}
                                        >
                                            Previous
                                        </div>
                                    
                                        <div style={{padding: "15px"}}>
                                            {currentPage + 1 }
                                        </div>                                    
                                        
                                        <div 
                                            className="balance" 
                                            style={{cursor: "pointer", width: "30px"}} 
                                            onClick={() => setCurrentPage(_ => _ + 1)}
                                        >Next</div>
                                    </div>      
                                </>
                                :
                                null
                            }
                                 

                        <Footer />                                 
                        
                    </div>
                    :
                    <>
                        <ConnectSection />
                        <Footer />                    
                    </> 
                }

                    <AttackPopup
                        visible={attackPopupVisible}
                        victim={victim}
                        damage={damage}
                        stolen={stolen}
                        result={result}
                        onClose={() => setAttackPopupVisible(false)}
                    />

                    <ToastContainer
                        position="top-right"
                        autoClose={16000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
            </div>
        </>
    )
}

export default ProviderHOC(Leaderboard)