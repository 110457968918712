import React, { useState, useEffect } from "react"

import ProviderHOC from "hoc/provider";
import Header from "components/header";
import Footer from "components/footer";
import ConnectSection from "components/connectSection";

import useContracts from "hooks/useContracts";
import Yogies from "adapters/yogies";
import Gemies from "adapters/gemies";
import YogiesItemShop from "adapters/yogiesItemShop";

import YogiesHouse from "adapters/yogiesHouse";
import YogiesGenesis from "adapters/yogiesGenesis";

import MobileError from "components/mobileError";
import Loader from "components/loader";
import WrongNetwork from "components/wrongNetwork";

import { EXTERNAL_URLS } from 'config'

import { formatNumber, getErrorMessage } from "utils";
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from "ethers";

function Shop({ provider }) {
    const isConnected = !!provider?.address;

    const [houseIsTx, setHouseIsTx] = useState();
    const [houseTxHash, setHouseTxHash] = useState();
    const [houseTxError, setHouseTxError] = useState();
    const [houseTxSuccess, setHouseTxSuccess] = useState();

    const [petIsTx, setPetIsTx] = useState();
    const [petTxHash, setPetTxHash] = useState();
    const [petTxError, setPetTxError] = useState();
    const [petTxSuccess, setPetTxSuccess] = useState();

    const [vaultIsTx, setVaultIsTx] = useState();
    const [vaultTxHash, setVaultTxHash] = useState();
    const [vaultTxError, setVaultTxError] = useState();
    const [vaultTxSuccess, setVaultTxSuccess] = useState();

    const [viyIsTx, setVIYIsTx] = useState();
    const [viyTxHash, setVIYTxHash] = useState();
    const [viyTxError, setVIYTxError] = useState();
    const [viyTxSuccess, setVIYTxSuccess] = useState();

    const [selectedTab, setSelectedTab] = useState(0);
    const [loaded, setLoaded] = useState();

    const [housesSelected, setHousesSelected] = useState(1);
    const [carsSelected, setCarsSelected] = useState(1);
    const [petsSelected, setPetsSelected] = useState(1);

    const [vaultSelected, setVaultSelected] = useState(1);
    const [viySelected, setVIYSelected] = useState(1);

    const  [
        wrongNetwork,
        contractYogies,
        contractYogiesGenesis,
        contractHouse,
        ,
        ,
        contractYogiesItemShop,
        contractGemies,
        ,
        ,
    ] = useContracts(provider);

    const [gemiesBalance, setGemiesBalance] = useState(0);
    
    const [housePrice, setHousePrice] = useState(0);
    const [carPrice, setCarPrice] = useState(0);
    const [petPrice, setPetPrice] = useState(0);

    const [houseLeft, setHouseLeft] = useState(0);
    const [carLeft, setCarLeft] = useState(0);
    const [petLeft, setPetLeft] = useState(0);

    const [vaultPrice, setVaultPrice] = useState(0);
    const [viyPrice, setVIYPrice] = useState(0);

    const [vaultLeft, setVaultLeft] = useState(0);
    const [viyLeft, setVIYLeft] = useState(0);

    const [houseBalance, setHouseBalance] = useState(0);
    const [genesisBalance, setGenesisBalance] = useState(0);
    const [viy, setViy] = useState(0);

    
    useEffect(() => {
        if (houseTxError) {
            toast.error(houseTxError, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }          
    }, [houseTxError])

    useEffect(() => {
        if (petTxError) {
            toast.error(petTxError, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }          
    }, [petTxError])

    useEffect(() => {
        if (vaultTxError) {
            toast.error(vaultTxError, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }          
    }, [vaultTxError])

    useEffect(() => {
        if (viyTxError) {
            toast.error(viyTxError, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }          
    }, [viyTxError])

    useEffect(() => {
        if (houseTxSuccess) {
            toast.success(houseTxSuccess, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }, [houseTxSuccess])

    useEffect(() => {
        if (petTxSuccess) {
            toast.success(petTxSuccess, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }, [petTxSuccess])

    useEffect(() => {
        if (vaultTxSuccess) {
            toast.success(vaultTxSuccess, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }, [vaultTxSuccess])

    useEffect(() => {
        if (viyTxSuccess) {
            toast.success(viyTxSuccess, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }, [viyTxSuccess])

    function isContractLoaded() {
        return provider && contractYogies && contractGemies && contractYogiesItemShop && contractYogiesGenesis && contractHouse;
    }

    async function fetchContracts() {
        let yogiesObj = new Yogies(contractYogies, provider.signer);
        let gemiesObj = new Gemies(contractGemies, provider.signer);     
        let yogiesItemShopObj = new YogiesItemShop(contractYogiesItemShop, provider.signer); 

        let houseObj = new YogiesHouse(contractHouse, provider.signer);
        let genesisObj = new YogiesGenesis(contractYogiesGenesis);
        
        let _gemiesBalance = await gemiesObj.getEcoSystemBalance(provider.address);
        setGemiesBalance(_gemiesBalance);

        let _housePrice = await yogiesItemShopObj.housePrice();
        let _carPrice = await yogiesItemShopObj.carPrice();
        let _petPrice = await yogiesItemShopObj.petPrice();

        setHousePrice(_housePrice);
        setCarPrice(_carPrice);
        setPetPrice(_petPrice);

        let _houseLeft = await yogiesItemShopObj.housesLeft();
        let _carLeft = await yogiesItemShopObj.carsLeft();
        let _petLeft = await yogiesItemShopObj.petsLeft();

        setHouseLeft(_houseLeft);
        setCarLeft(_carLeft);
        setPetLeft(_petLeft);

        let _vaultPrice = await yogiesObj.vaultPriceYogie();
        let _viyPrice = await yogiesObj.vaultPriceVIY();

        setVaultPrice(_vaultPrice);
        setVIYPrice(_viyPrice);

        let _vaultLeft = await yogiesObj.getYogiesLeftInVault();
        let _viyLeft = await yogiesObj.getVIYsLeftInVault();

        setVaultLeft(_vaultLeft);
        setVIYLeft(_viyLeft);

        let _houseBalance = await houseObj.balanceOf(provider.address)
        setHouseBalance(_houseBalance);

        let _genesisBalance = await genesisObj.balanceOf(provider.address);
        setGenesisBalance(_genesisBalance);

        setLoaded(true)
    }

    useEffect(() => {
        if (isContractLoaded()) {
            fetchContracts();
          provider.instance.on("block", (_) => {
            fetchContracts();
          });
          return () => provider.instance.off("block");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractYogies, contractYogiesItemShop, contractGemies]);

    async function onBuyHouse() {
        if (!houseIsTx && contractYogiesItemShop) {
            setHouseIsTx();
            setHouseTxHash();
            setHouseTxError();
            setHouseTxSuccess();

            let amount = parseInt(housesSelected);

            if (isNaN(amount)) {
                setHouseTxError("Please enter a valid mansion amount");
                return;
            }

            if (houseLeft.lt(amount)) {
                setHouseTxError(`No more mansions left to complete your purchase. Please check back at 6pm CEST`);
                return;
            }

            if (housePrice.mul(amount).gt(gemiesBalance)) {
                setHouseTxError(`You do not have enough Gemies to complete the purchase`);
                return;
            }
            
            if (houseLeft.lte(0)) {
                setHouseTxError("No more mansions left");
                return;
            }
            
            let yogiesItemShopObj = new YogiesItemShop(contractYogiesItemShop, provider.signer); 

            try {
                setHouseIsTx(true);
                let tx = await yogiesItemShopObj.buyHouses(amount, 0, genesisBalance.gt(0));
                setHouseTxHash(tx.hash);
                await tx.wait();
                setHouseTxSuccess(`Successfully bought ${amount} mansion${amount === 1 ? "" : "s"}`)
            } catch (e) {
                setHouseTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setHouseIsTx();
            setHouseTxHash();
        }
    }    

    async function onBuyPet() {
        if (!petIsTx && contractYogiesItemShop) {
            setPetIsTx();
            setPetTxHash();
            setPetTxError();
            setPetTxSuccess();
    
            let amount = parseInt(petsSelected);
    
            if (isNaN(amount)) {
                setPetTxError("Please enter a valid pet amount");
                return;
            }
    
            if (petLeft.lt(amount)) {
                setPetTxError(`The maximum pet you can buy is ${petLeft.toString()}`);
                return;
            }
    
            if (petPrice.mul(amount).gt(gemiesBalance)) {
                setPetTxError(`You do not have enough Gemies to complete the purchase`);
                return;
            }
            
            if (petLeft.lte(0)) {
                setPetTxError("No more pets left to complete the purchase. Please check back at 6pm CEST");
                return;
            }

            //if (houseBalance.lte(0)) {
            //    setPetIsTx("You need to own at least 1 mansion to buy a pet")
            //    return;
            //}
            
            let yogiesItemShopObj = new YogiesItemShop(contractYogiesItemShop, provider.signer); 
    
            try {
                setPetIsTx(true);
                let tx = await yogiesItemShopObj.buyPets(amount, 0, genesisBalance.gt(0));
                setPetTxHash(tx.hash);
                await tx.wait();
                setPetTxSuccess(`Successfully bought ${amount} security pet${amount === 1 ? "" : "s"}`)
            } catch (e) {
                setPetTxError(getErrorMessage(e));
            }
    
            await fetchContracts();
    
            setPetIsTx();
            setPetTxHash();
        }
    }   

    async function onBuyVault() {
        if (!vaultIsTx && contractYogiesItemShop) {
            setVaultIsTx();
            setVaultTxHash();
            setVaultTxError();
            setVaultTxSuccess();
    
            let amount = parseInt(vaultSelected);
    
            if (isNaN(amount)) {
                setVaultTxError("Please enter a valid Yogie amount");
                return;
            }
    
            if (vaultLeft.lt(amount)) {
                setVaultTxError(`The maximum Yogies you can buy is ${vaultLeft.toString()}`);
                return;
            }
    
            if (vaultPrice.mul(amount).gt(gemiesBalance)) {
                setVaultTxError(`You do not have enough Gemies to complete the purchase`);
                return;
            }
            
            if (vaultLeft.lte(0)) {
                setVaultTxError("No more Yogies left");
                return;
            }
            
            let yogiesObj = new Yogies(contractYogies, provider.signer); 
    
            try {
                setVaultIsTx(true);
                let tx = await yogiesObj.unlockYogieFromVault();
                setVaultTxHash(tx.hash);
                await tx.wait();
                setVaultTxSuccess(`Successfully unlocked a regular Yogie from the vault`)
            } catch (e) {
                setVaultTxError(getErrorMessage(e));
            }
    
            await fetchContracts();
    
            setVaultIsTx();
            setVaultTxHash();
        }
    }   

    async function onBuyVIY() {
        if (!viyIsTx && contractYogiesItemShop) {
            setVIYIsTx();
            setVIYTxHash();
            setVIYTxError();
            setVIYTxSuccess();
    
            let amount = parseInt(viySelected);
    
            if (isNaN(amount)) {
                setVIYTxError("Please enter a valid VIY amount");
                return;
            }
    
            if (viyLeft.lt(amount)) {
                setVIYTxError(`The maximum VIYs you can buy is ${viyLeft.toString()}`);
                return;
            }
    
            if (viyPrice.mul(amount).gt(gemiesBalance)) {
                setVIYTxError(`You do not have enough Gemies to complete the purchase`);
                return;
            }
            
            if (viyLeft.lte(0)) {
                setVIYTxError("No more VIYs left");
                return;
            }
            
            let yogiesObj = new Yogies(contractYogies, provider.signer); 
    
            try {
                setVIYIsTx(true);
                let tx = await yogiesObj.unlockVIYFromVault();
                setVIYTxHash(tx.hash);
                await tx.wait();
                setVIYTxSuccess(`Successfully unlocked a VIY from the vault`)
            } catch (e) {
                setVIYTxError(getErrorMessage(e));
            }
    
            await fetchContracts();
    
            setVIYIsTx();
            setVIYTxHash();
        }
    }   

    return (
        <>  
            <MobileError />
            <div className="notsupport">
                {
                    isConnected &&
                    <Header 
                        active={2}
                    />
                }

                {
                    isConnected ? 
                    <div className="box_dash"> 
                        <div className="w3-container">
                            <div className="w3-row">
                                <span onClick={() => setSelectedTab(0)}>
                                    <div className={`w3-third tablink w3-bottombar w3-hover-light-grey w3-padding ${selectedTab === 0 ? "w3-border-red" : null}`} style={{width: "50%"}}>Boosts</div>
                                </span>
                                <span onClick={() => setSelectedTab(1)}>
                                    <div className={`w3-third tablink w3-bottombar w3-hover-light-grey w3-padding ${selectedTab === 1 ? "w3-border-red" : null}`} style={{width: "50%"}}>Vault Yogies</div>
                                </span>
                            </div>
                        </div>

                        <Loader text={"Loading Boosts and Vault"} show={!loaded} />

                        {
                            selectedTab === 0 && loaded &&
                            <div id="Boosts" className="w3-container Yogie" >
                                <div className="box_staking">
                                    <img src="/images/mansion.png" style={{borderRadius: "20px"}}/>
                                    <div className="yogienumber">Mansion
                                        <div className="yogienumber2">Mansion allows you to stake up to 10 Yogies at a time. <br/>(Each Mansion is 10 staking slots) <br/>
                                            <div className="yogienumber3">{formatNumber(ethers.utils.formatEther(housePrice))} $Gemies
                                                <div className="yogienumber4"> <span style={{cursor: "pointer"}} onClick={() => setHousesSelected(_ => _ - 1 < 1 ? 1 : _ - 1)}>-</span> {housesSelected} <span style={{cursor: "pointer"}} onClick={() => setHousesSelected(_ => _ + 1)}>+</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !houseIsTx ?
                                        <div className="button_shop" onClick={onBuyHouse}>PURCHASE</div>
                                        :
                                        <div className="button_shop" onClick={() => window.open(EXTERNAL_URLS.etherscan + houseTxHash, "_blank")}>VIEW TX</div>
                                    }
                                    <div className="yogienumber" style={{textAlign: "center", fontSize: "12px", color: "#fff"}}>{formatNumber(houseLeft.toString())} REMAINING <br/>100 EVERY 24 HOURS UNTIL 888</div>
                                </div>
                                
                                <div className="box_staking">
                                    <img src="/images/cat.png" style={{borderRadius: "20px"}}/>
                                    <div className="yogienumber">Security Pet
                                        <div className="yogienumber2">Protect your daily yield from attackers from YogieLand. <br/>(You only need one Security Pet) <br/>
                                            <div className="yogienumber3">{formatNumber(ethers.utils.formatEther(petPrice))} $Gemies
                                                <div className="yogienumber4"><span style={{cursor: "pointer"}} onClick={() => setPetsSelected(_ => _ - 1 < 1 ? 1 : _ - 1)}>-</span> {petsSelected} <span style={{cursor: "pointer"}} onClick={() => setPetsSelected(_ => _ + 1)}>+</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !petIsTx ?
                                        <div className="button_shop" onClick={onBuyPet}>PURCHASE</div>
                                        :
                                        <div className="button_shop" onClick={() => window.open(EXTERNAL_URLS.etherscan + petTxHash, "_blank")}>VIEW TX</div>
                                    }
                                    <div className="yogienumber" style={{textAlign: "center", fontSize: "12px", color: "#fff"}}>{formatNumber(petLeft.toString())} REMAINING <br/>22 EVERY 24 HOURS UNTIL 444</div>
                                </div>
                                
                                <div className="box_staking">
                                    <img src="/images/car.png" style={{borderRadius: "20px"}}/>
                                    <div className="yogienumber">Flying Car
                                        <div className="yogienumber2">Boost your daily yield up to 50%. <br/>(One Car = 25%, Two Cars = 35%, Three Cars = 50%) <br/>
                                            <div className="yogienumber3">??? $Gemies
                                                <div className="yogienumber4">- 1 +</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button_shop" style={{background: "rgb(137, 137, 137)", border: "2px solid rgb(223, 223, 223)", cursor: "pointer", color: "#061630"}}>SOON</div>
                                    <div className="yogienumber" style={{textAlign: "center", fontSize: "12px", color: "#fff"}}>COMING VERY SOON<br/>KEEP AN EYE ON OUR ITEMS SHOP</div>
                                </div>
                            </div>
                        }
                    
                        {
                            selectedTab === 1 && loaded &&
                            <div id="Vault" className="w3-container Yogie" >
                                <div className="box_staking" style={{maxWidth: "49%"}}>
                                    <img src="/images/normal.gif" style={{borderRadius: "20px"}}/>
                                    <div className="yogienumber">Regular Yogie
                                        <div className="yogienumber2">Save a Regular Yogie from the vault. These Yogies have one unique trait that other Yogies don't have. <br/>
                                            <div className="yogienumber3">{formatNumber(ethers.utils.formatEther(vaultPrice))} $Gemies                                               
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !vaultIsTx ?
                                        <div className="button_shop" onClick={onBuyVault}>PURCHASE</div>
                                        :
                                        <div className="button_shop" onClick={() => window.open(EXTERNAL_URLS.etherscan + vaultTxHash, "_blank")}>VIEW TX</div>
                                    }
                                    <div className="yogienumber" style={{textAlign: "center", fontSize: "12px", color: "#fff"}}>{formatNumber(vaultLeft.toString())} REMAINING</div>
                                </div>
                                
                                <div className="box_staking" style={{maxWidth: "49%"}}>
                                    <img src="/images/viy.gif" style={{borderRadius: "20px"}}/>
                                    <div className="yogienumber">Very Important Yogie
                                        <div className="yogienumber2">Save a VIY from the vault. These Yogies have multiple unique traits that other Yogies don't have. <br/>
                                            <div className="yogienumber3">{formatNumber(ethers.utils.formatEther(viyPrice))} $Gemies                                                
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !viyIsTx ?
                                        <div className="button_shop" onClick={onBuyVIY}>PURCHASE</div>
                                        :
                                        <div className="button_shop" onClick={() => window.open(EXTERNAL_URLS.etherscan + viyTxHash, "_blank")}>VIEW TX</div>
                                    }
                                    <div className="yogienumber" style={{textAlign: "center", fontSize: "12px", color: "#fff"}}>{formatNumber(viyLeft.toString())} REMAINING</div>
                                </div>
                            </div>
                        }
                        
                        {
                            loaded &&
                            <>
                                <div className="balancebox2"></div>
                                <div className="yogienumber" style={{textAlign: "center", fontSize: "12px", color: "#efb615", color: "#ffffff", marginTop: "0%"}}>
                                    YOUR BALANCE
                                </div>
                                <div className="balancebox">
                                    <div className="balance">{formatNumber(ethers.utils.formatEther(gemiesBalance))} $GEMIES</div>
                                </div>
                            </>
                        }

                        <ToastContainer
                            position="top-right"
                            autoClose={8000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                         <Footer /> 
                    </div>
                    :
                    wrongNetwork ?
                    <>
                        <WrongNetwork current={wrongNetwork} provider={provider} />
                        <Footer /> 
                    </>
                    :
                    <>
                        <ConnectSection />
                        <Footer />                    
                    </>   
                }
            </div>
        </>
    )
}

export default ProviderHOC(Shop)