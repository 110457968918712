export default class YogiesHouse {
    constructor(instance, signer) {
        this.instance = instance.connect(signer);
    }

    balanceOf(address) {
        return this.instance.balanceOf(address)
    }

    isApprovedForAll(address, operator) {
        return this.instance.isApprovedForAll(address, operator)
    }

    setApprovalForAll(operator, isApproved) {
        return this.instance.setApprovalForAll(operator, isApproved);
    }
}