import React from "react"
import { Link } from "react-router-dom"
import CountdownTimer from "components/countdownTimer";
import { DATA } from "config";

export default function Header({ active }) {

    return (
        <>
            <div className="headbox">
                {/*<button style={{
                    color: "rgb(255, 255, 255)",
                    padding: "1px 6px",
                    fontSize: "9px",
                    borderRadius: "4px",
                    backgroundColor: "rgb(167, 0, 0)",
                    border: "2px solid rgb(255, 128, 128)",
                    fontWeight: "bolder",
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    top: "-25%",
                    right: "93.5%"
                }}><span>1</span></button>*/}
            

                <button style={{
                    color: "#ffffff",
                    padding: "1px 6px 1px 6px",
                    fontSize: "8px",
                    borderRadius: "4px",
                    backgroundColor: "#a70000",
                    border: "2px solid #ff8080",
                    fontWeight: "bolder",
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    top: "-25%",
                    right: "0%"
                }}>
                    <CountdownTimer to={DATA.UPDATE_START} show={DATA.COUNTDOWN_ACTIVE} />
                </button>
                
                <Link to="/notification" class={active === -1 ? "head_active" : "head_inactive"} style={{width: "40px"}}>
                    <i class="fas fa-bell" style={{height: "18px"}}></i>
                </Link>
                <Link to="/" class={active === 0 ? "head_active" : "head_inactive"}>PROFILE</Link>
                <Link to="/staking" class={active === 1 ? "head_active" : "head_inactive"}>STAKING</Link>

                <Link to="/shop" class={active === 2 ? "head_active" : "head_inactive"}>ITEM SHOP</Link>
                <Link to="/leaderboard" class={active === 3 ? "head_active" : "head_inactive"}>LEADERBOARD</Link>
                <span class={active === 4 ? "head_active" : "head_inactive"} style={{cursor: "pointer"}}>EXCHANGE</span>
                {
                    /*                    
                    <Link to="/exchange" class={active === 4 ? "head_active" : "head_inactive"}>EXCHANGE</Link>
                    */
                }
            </div>
        </>
    )
}