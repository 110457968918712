export default class Yogies {
    constructor(instance, signer) {
        this.instance = instance.connect(signer);
    }

    unlockYogieFromVault() {
        return this.instance.unlockYogieFromVault();
    }

    unlockVIYFromVault() {
        return this.instance.unlockVIYFromVault();
    }

    vaultStartPoint() {
        return this.instance.vaultStartPoint();
    }

    viyStartPoint() {
        return this.instance.viyStartPoint();
    }

    getYogiesLeftInVault() {
        return this.instance.getYogiesLeftInVault();
    }

    getVIYsLeftInVault() {
        return this.instance.getVIYsLeftInVault();
    }

    getYogiesOfUser(address) {
        return this.instance.getYogiesOfUser(address);
    }

    getGYogiesOfUser(address) {
        return this.instance.getGYogiesOfUser(address);
    }

    getStakedYogiesOfUser(address) {
        return this.instance.getStakedYogiesOfUser(address);
    }

    getStakedGYogiesOfUser(address) {
        return this.instance.getStakedGYogiesOfUser(address);
    }

    vaultPriceYogie() {
        return this.instance.vaultPriceYogie();
    }

    vaultPriceVIY() {
        return this.instance.vaultPriceVIY();
    }

    balanceOf(address) {
        return this.instance.balanceOf(address)
    }
}