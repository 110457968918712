import React from "react"
import { NETWORK } from "config"
import FloatingObjects from "components/floatingObjects";

import { ToastContainer, toast } from 'react-toastify';

export default function WrongNetwork({ current, provider }) {

    async function metamaskSwitchNetwork() {
        try {
            await provider.instance.send('wallet_switchEthereumChain', [{ chainId: "0x" + NETWORK.TARGET_CHAIN_ID }]);
            setTimeout(() => {
                window.location.reload();
            }, 1);
        } catch(e) {
            toast.error(`Please open your wallet application, switch to the ${NETWORK.CHAIN_ID_TO_NAME[NETWORK.TARGET_CHAIN_ID]} network and refresh the page.`, {
                position: "top-right",
                autoClose: 15000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <>
            <FloatingObjects/>
            <img src="/images/logo2.png" style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50px", marginTop: "5%"}}/>
            <div class="box3_mint" style={{height: "40px"}}></div>
            <div class="box_connect" style={{backgroundColor: "#061630", padding: "30px", marginLeft: "auto", marginRight: "auto", width: "400px", position: "sticky"}}>  
                <div class="connect" style={{verticalAlign: "middle", fontSize: "16px", fontFamily: 'Russo One, sans-serif', textAlign: "center"}}>
                    Oops! Wrong Network
                </div>
                <div class="connect2" style={{textAlign: "center", fontSize: "11px", fontFamily: "sans-serif", color: "#ffffffa3"}}>
                    You are connected to the wrong network. You are currently connected to the { current } network. Please
                    switch to the {NETWORK.CHAIN_ID_TO_NAME[NETWORK.TARGET_CHAIN_ID]} network in your wallet.
                </div>
            </div>              
            <div class="box2_mint"> 
                <div class="button_mint" onClick={metamaskSwitchNetwork}>SWITCH</div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={15000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>  
    )
}