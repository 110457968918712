import React from "react"
import { 
    getTruncatedAddress,
    formatSeconds
} from "../../utils"

export default function AttackPopup({ visible, victim, damage, stolen, result, onClose }) {
    return (
        <>
            {
                visible ?
                <>
                    <div class="xpopup"></div> 
                    <div style={{zIndex: "999999px", position: "absolute", top: "20%", left: "35%"}}>                    
                        <div class="box_success" style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "12%",
                            width: "450px",
                            position: "relative"
                        }}>
                            <img src="/images/success.png" style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "20%"
                            }} />
                                <div class="box_inside4"></div>
                                <div class="box_inside6">{result ? "YOU HAVE ATTACKED" : "YOU ARE ATTACKING"}</div>
                                <div class="box_inside3">{getTruncatedAddress(victim)}</div>
                                <div class="box_inside4"></div>
                                {
                                    result ?
                                        <>
                                            <div class="box_inside6">YOUR ATTACK DAMAGE</div>
                                            <div class="box_inside3">{damage}%</div>
                                            <div class="box_inside4"></div>
                                            <div class="box_inside6">TOTAL $GEMIES STOLEN</div>
                                            <div class="box_inside3">{stolen} $GEMIES</div><br/>
                                        </>
                                    :
                                        null
                                }                            
                                
                                <div class="box_inside" style={{backgroundColor: result === "success" ? "#80ffc9" : "red"}}>
                                    {
                                        result ?
                                            result === "success" ?
                                                "SUCCESSFULL ATTACK"
                                            :
                                                "ATTACK FAILED"
                                        :
                                            "ATTACK IN PROGRESS..."
                                    }
                                </div>
                                <div style={{textAlign: "center", fontSize: "12px", marginTop: "2%", textDecoration: "underline!important", cursor: 'pointer'}} onClick={onClose}>close</div>
                        </div>
                    </div>
                </>
                
                :
                <></>
            }            
        </>
    )
}