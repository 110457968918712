import React, { useState } from "react"

import { getUnixTimeStamp } from "utils";
import useClock from "hooks/useClock";

export default function CountdownTimer({ to, show }) {
    const [now, setNow] = useState(getUnixTimeStamp());

    useClock(() => {
        setNow(_ => getUnixTimeStamp())
    })

    const difference = Math.max(to - now, 0);

    const sec = 1;
    const min = sec * 60;
    const hr = min * 60;

    let textHour = Math.floor(difference / hr);
    let textMin = Math.floor((difference % hr) / min);
    let textSec = Math.floor((difference % min) / sec);
    

    if (textHour.toString().length === 1) {
        textHour = "0" + textHour;
    }

    if (textMin.toString().length === 1) {
        textMin = "0" + textMin;
    }

    if (textSec.toString().length === 1) {
        textSec = "0" + textSec;
    }

    return (
        <>
            {
                show &&
                <>
                    <span>SOON</span>
                </>
            }
        </>
    )
}