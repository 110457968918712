import { useState, useEffect } from 'react';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeApp } from 'firebase/app';

import { FIRESTORE, NETWORK } from '../config';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  };

export function getFireBase() {
    //let firebaseConfig = FIRESTORE[NETWORK.TARGET_CHAIN_ID.toString()]
    const app = initializeApp(firebaseConfig)

    const firestore = getFirestore(app);
    const functions = getFunctions(app);

    if (NETWORK.TARGET_CHAIN_ID === 1337) {
        console.log("connect local")
        connectFunctionsEmulator(functions, 'localhost', 5001);
        connectFirestoreEmulator(firestore, 'localhost', 8080);       
    }

    return [firestore, functions]
}