import React, { useState, useEffect } from "react"

import ProviderHOC from "hoc/provider";
import Header from "components/header";
import Footer from "components/footer";
import ConnectSection from "components/connectSection";

import useContracts from "hooks/useContracts";
import Gemies from "adapters/gemies";
import { formatNumber, removeDecimals, getErrorMessage, regularNumberToGemies } from "utils";

import MobileError from "components/mobileError";
import Loader from "components/loader";
import WrongNetwork from "components/wrongNetwork";

import {EXTERNAL_URLS} from "config"

function Exchange({ provider }) {
    const isConnected = !!provider?.address;

    const  [
        wrongNetwork,
        ,
        ,
        ,
        ,
        ,
        ,
        contractGemies,
        ,
        
      ] = useContracts(provider);

    const [ecoBalance, setEcoBalance] = useState();
    const [erc20Balance, setErc20Balance] = useState();

    const [depositAmount, setDepositAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);

    const [depositIsTx, setDepositIsTx] = useState();
    const [depositTxHash, setDepositTxHash] = useState();
    const [depositTxError, setDepositTxError] = useState();

    const [withdrawIsTx, setWithdrawIsTx] = useState();
    const [withdrawTxHash, setWithdrawTxHash] = useState();
    const [withdrawTxError, setWithdrawTxError] = useState();

    function isContractLoaded() {
        return provider && contractGemies;
    }

    async function fetchContracts() {
        let gemiesObj = new Gemies(contractGemies, provider.signer);

        let _ecoBalance = await gemiesObj.getEcoSystemBalance(provider.address);
        let _erc20Balance = await gemiesObj.balanceOf(provider.address);

        setEcoBalance(_ecoBalance);
        setErc20Balance(_erc20Balance);
    }   

    useEffect(() => {
        if (isContractLoaded()) {
            fetchContracts();
          provider.instance.on("block", (_) => {
            fetchContracts();
          });
          return () => provider.instance.off("block");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractGemies]);


    async function onDeposit() {
        if (!depositIsTx && contractGemies) {
            setDepositIsTx();
            setDepositTxHash();
            setDepositTxError();
            
            let gemiesObj = new Gemies(contractGemies, provider.signer);

            if (isNaN(depositAmount) || depositAmount <= 0) {
                setDepositTxError("Error: Please enter a valid deposit amount");
                return;
            }

            if (erc20Balance.lt(depositAmount)) {
                setDepositTxError(`Error: You can not deposit more than ${removeDecimals(erc20Balance)} Gemies`);
                return;
            }

            try {
                setDepositIsTx(true);
                let tx = await gemiesObj.depositInEcosystem(regularNumberToGemies(depositAmount))
                setDepositTxHash(tx.hash);
                await tx.wait();        
            } catch (e) {
                setDepositTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setDepositAmount(0)

            setDepositIsTx();
            setDepositTxHash();
        }
    }

    async function onWithdraw() {
        if (!withdrawIsTx && contractGemies) {
            setWithdrawIsTx();
            setWithdrawTxHash();
            setWithdrawTxError();
            
            let gemiesObj = new Gemies(contractGemies, provider.signer);
        
            if (isNaN(withdrawAmount) || withdrawAmount <= 0) {
                setWithdrawTxError("Error: Please enter a valid withdraw amount");
                return;
            }
        
            if (ecoBalance.lt(withdrawAmount)) {
                setWithdrawTxError(`Error: You can not withdraw more than ${removeDecimals(ecoBalance)} Gemies`);
                return;
            }
        
            try {
                setWithdrawIsTx(true);
                let tx = await gemiesObj.withdrawFromEcosystem(regularNumberToGemies(withdrawAmount))
                setWithdrawTxHash(tx.hash);
                await tx.wait();        
            } catch (e) {
                setWithdrawTxError(getErrorMessage(e));
            }
        
            await fetchContracts();
            
            setWithdrawAmount(0)
        
            setWithdrawIsTx();
            setWithdrawTxHash();
        }
    }

    const handleDepositAmountChange = e => {
        setDepositAmount(e.target.value);
    }

    const handleWithdrawAmountChange = e => {
        setWithdrawAmount(e.target.value);
    }

    return (
        <>
            <MobileError />
            
            <div className="notsupport">
                {
                    isConnected &&
                    <Header 
                        active={4}
                    />
                }

                {
                    isConnected ? 
                    <div class="box_dash"> 
                        <div class="box_dash3">
                            <div class="box_balance3">{ecoBalance ? formatNumber(removeDecimals(ecoBalance)) : 0}</div>DEPOSITED GEMIES
                        </div>
                        <div class="box_dash4">
                            <div class="box_balance4">{erc20Balance ? formatNumber(removeDecimals(erc20Balance)) : 0}</div>WALLET GEMIES
                        </div>
                        <div class="box_dash5">Deposit your $Gemies from your wallet to start spending in our item shop.<br/><br/>Please note that you cannot spend your wallet $Gemies unless they are deposited.</div>
                        <div class="box_dash2"> 

                            {
                                !depositIsTx && !withdrawIsTx &&
                                <>
                                    <span>ENTER THE AMOUNT YOU WOULD LIKE TO DEPOSIT:</span>
                                    <input 
                                        type="number"
                                        min="0"
                                        onChange={handleDepositAmountChange}
                                        value={depositAmount}
                                    />                        
                                    <div class="button_sign" onClick={onDeposit} style={{cursor: "pointer"}}>DEPOSIT</div>
                                </>
                            }

                            {
                                depositIsTx && !depositTxError &&
                                <>
                                    {
                                        !depositTxHash &&
                                        <div>PLEASE CONFIRM THE DEPOSIT TRANSACTION IN YOUR WALLET</div>
                                    }

                                    {
                                        depositTxHash &&
                                        <>
                                            <span>DEPOSIT IN PROGRESS</span>
                                            <div class="button_sign" style={{cursor: "pointer"}} onClick={() => window.open(EXTERNAL_URLS.etherscan + depositTxHash, "_blank")}>VIEW TRANSACTION</div>
                                        </>                                    
                                    }                                
                                </>
                            }

                            {
                                depositTxError &&
                                <>
                                    <div style={{color: "red"}}>{depositTxError}</div>
                                </>
                            }
                            
                            
                            {/*
                                !withdrawIsTx && !depositIsTx &&
                                <>
                                    <span>ENTER THE AMOUNT YOU WOULD LIKE TO WITHDRAW:</span>
                                    <input
                                        type="number"
                                        min="0"
                                        onChange={handleWithdrawAmountChange}
                                        value={withdrawAmount}
                                    />
                                    <div class="button_sign" style={{cursor: "pointer"}} onClick={onWithdraw}>WITHDRAW</div>
                                </>
                            }

                            {
                                withdrawIsTx && !withdrawTxError &&
                                <>
                                    {
                                        !withdrawTxHash &&
                                        <div>PLEASE CONFIRM THE WITHDRAW TRANSACTION IN YOUR WALLET</div>
                                    }

                                    {
                                        withdrawTxHash &&
                                        <>
                                            <span>WITHDRAWAL IN PROGRESS</span>
                                            <div class="button_sign" style={{cursor: "pointer"}} onClick={() => window.open(EXTERNAL_URLS.etherscan + withdrawTxHash, "_blank")}>VIEW TRANSACTION</div>
                                        </>
                                        
                                    }                                
                                </>
                            }

                            {
                                withdrawTxError &&
                                <>
                                    <div style={{color: "red"}}>{withdrawTxError}</div>
                                </>
                            */}
                        </div>
                        <Footer />
                    </div>
                    :
                    wrongNetwork ?
                    <>
                        <WrongNetwork current={wrongNetwork} provider={provider} />
                        <Footer /> 
                    </>
                    :
                    <>
                        <ConnectSection />
                        <Footer />                    
                    </>   
                }
            </div> 
        </>
    )
}

export default ProviderHOC(Exchange)

/*import React, { useState, useEffect } from "react"

import ProviderHOC from "hoc/provider";
import Header from "components/header";
import Footer from "components/footer";
import ConnectSection from "components/connectSection";

import useContracts from "hooks/useContracts";
import Gemies from "adapters/gemies";
import { formatNumber, removeDecimals, getErrorMessage, regularNumberToGemies } from "utils";

import MobileError from "components/mobileError";

import {EXTERNAL_URLS} from "config"

function Exchange({ provider }) {
    const isConnected = !!provider?.address;

    const  [
        ,
        ,
        ,
        ,
        contractGemies,
        ,
        
    ] = useContracts(provider);

    const [ecoBalance, setEcoBalance] = useState();
    const [erc20Balance, setErc20Balance] = useState();

    const [depositAmount, setDepositAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);

    const [depositIsTx, setDepositIsTx] = useState();
    const [depositTxHash, setDepositTxHash] = useState();
    const [depositTxError, setDepositTxError] = useState();

    const [withdrawIsTx, setWithdrawIsTx] = useState();
    const [withdrawTxHash, setWithdrawTxHash] = useState();
    const [withdrawTxError, setWithdrawTxError] = useState();

    function isContractLoaded() {
        return provider && contractGemies;
    }

    async function fetchContracts() {
        let gemiesObj = new Gemies(contractGemies, provider.signer);

        let _ecoBalance = await gemiesObj.getEcoSystemBalance(provider.address);
        let _erc20Balance = await gemiesObj.balanceOf(provider.address);

        setEcoBalance(_ecoBalance);
        setErc20Balance(_erc20Balance);
    }   

    useEffect(() => {
        if (isContractLoaded()) {
          provider.instance.on("block", (_) => {
            fetchContracts();
          });
          return () => provider.instance.off("block");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractGemies]);


    async function onDeposit() {
        if (!depositIsTx && contractGemies) {
            setDepositIsTx();
            setDepositTxHash();
            setDepositTxError();
            
            let gemiesObj = new Gemies(contractGemies, provider.signer);

            if (isNaN(depositAmount) || depositAmount <= 0) {
                setDepositTxError("Error: Please enter a valid deposit amount");
                return;
            }

            if (erc20Balance.lt(depositAmount)) {
                setDepositTxError(`Error: You can not deposit more than ${removeDecimals(erc20Balance)} Gemies`);
                return;
            }

            try {
                setDepositIsTx(true);
                let tx = await gemiesObj.depositInEcosystem(regularNumberToGemies(depositAmount))
                setDepositTxHash(tx.hash);
                await tx.wait();        
            } catch (e) {
                setDepositTxError(getErrorMessage(e));
            }

            await fetchContracts();

            setDepositAmount(0)

            setDepositIsTx();
            setDepositTxHash();
        }
    }

    async function onWithdraw() {
        if (!withdrawIsTx && contractGemies) {
            setWithdrawIsTx();
            setWithdrawTxHash();
            setWithdrawTxError();
            
            let gemiesObj = new Gemies(contractGemies, provider.signer);
        
            if (isNaN(withdrawAmount) || withdrawAmount <= 0) {
                setWithdrawTxError("Error: Please enter a valid withdraw amount");
                return;
            }
        
            if (ecoBalance.lt(withdrawAmount)) {
                setWithdrawTxError(`Error: You can not withdraw more than ${removeDecimals(ecoBalance)} Gemies`);
                return;
            }
        
            try {
                setWithdrawIsTx(true);
                let tx = await gemiesObj.withdrawFromEcosystem(regularNumberToGemies(withdrawAmount))
                setWithdrawTxHash(tx.hash);
                await tx.wait();        
            } catch (e) {
                setWithdrawTxError(getErrorMessage(e));
            }
        
            await fetchContracts();
            
            setWithdrawAmount(0)
        
            setWithdrawIsTx();
            setWithdrawTxHash();
        }
    }

    const handleDepositAmountChange = e => {
        setDepositAmount(e.target.value);
    }

    const handleWithdrawAmountChange = e => {
        setWithdrawAmount(e.target.value);
    }

    return (
        <>
            <MobileError />
            
            <div className="notsupport">
                {
                    isConnected &&
                    <Header 
                        active={4}
                    />
                }

                {
                    isConnected ? 
                    <div class="box_dash"> 
                        <div class="box_dash3">
                            <div class="box_balance3">{ecoBalance ? formatNumber(removeDecimals(ecoBalance)) : 0}</div>DEPOSITED GEMIES
                        </div>
                        <div class="box_dash4">
                            <div class="box_balance4">{erc20Balance ? formatNumber(removeDecimals(erc20Balance)) : 0}</div>WALLET GEMIES
                        </div>
                        <div class="box_dash5">Deposit your $Gemies from your wallet to start spending in our item shop. You can withdraw your $Gemies back to your wallet anytime you wish.<br/><br/>Please note that you cannot spend your wallet $Gemies unless they are deposited.</div>
                        <div class="box_dash2"> 

                            {
                                !depositIsTx && !withdrawIsTx &&
                                <>
                                    <span>ENTER THE AMOUNT YOU WOULD LIKE TO DEPOSIT:</span>
                                    <input 
                                        type="number"
                                        min="0"
                                        onChange={handleDepositAmountChange}
                                        value={depositAmount}
                                    />                        
                                    <div class="button_sign" onClick={onDeposit} style={{cursor: "pointer"}}>DEPOSIT</div>
                                </>
                            }

                            {
                                depositIsTx && !depositTxError &&
                                <>
                                    {
                                        !depositTxHash &&
                                        <div>PLEASE CONFIRM THE DEPOSIT TRANSACTION IN YOUR WALLET</div>
                                    }

                                    {
                                        depositTxHash &&
                                        <>
                                            <span>DEPOSIT IN PROGRESS</span>
                                            <div class="button_sign" style={{cursor: "pointer"}} onClick={() => window.open(EXTERNAL_URLS.etherscan + depositTxHash, "_blank")}>VIEW TRANSACTION</div>
                                        </>                                    
                                    }                                
                                </>
                            }

                            {
                                depositTxError &&
                                <>
                                    <div style={{color: "red"}}>{depositTxError}</div>
                                </>
                            }
                            
                            
                            {
                                !withdrawIsTx && !depositIsTx &&
                                <>
                                    <span>ENTER THE AMOUNT YOU WOULD LIKE TO WITHDRAW:</span>
                                    <input
                                        type="number"
                                        min="0"
                                        onChange={handleWithdrawAmountChange}
                                        value={withdrawAmount}
                                    />
                                    <div class="button_sign" style={{cursor: "pointer"}} onClick={onWithdraw}>WITHDRAW</div>
                                </>
                            }

                            {
                                withdrawIsTx && !withdrawTxError &&
                                <>
                                    {
                                        !withdrawTxHash &&
                                        <div>PLEASE CONFIRM THE WITHDRAW TRANSACTION IN YOUR WALLET</div>
                                    }

                                    {
                                        withdrawTxHash &&
                                        <>
                                            <span>WITHDRAWAL IN PROGRESS</span>
                                            <div class="button_sign" style={{cursor: "pointer"}} onClick={() => window.open(EXTERNAL_URLS.etherscan + withdrawTxHash, "_blank")}>VIEW TRANSACTION</div>
                                        </>
                                        
                                    }                                
                                </>
                            }

                            {
                                withdrawTxError &&
                                <>
                                    <div style={{color: "red"}}>{withdrawTxError}</div>
                                </>
                            }
                        </div>
                        <Footer />
                    </div>
                    :
                    <>
                        <ConnectSection />
                        <Footer />
                    </> 
                }
            </div> 
        </>
    )
}

export default ProviderHOC(Exchange)*/