import Profile from "pages/profile";
import Staking from "pages/staking";
import Shop from "pages/shop";
import Leaderboard from "pages/leaderboard";
import Exchange from "pages/exchange";
import Notification from "pages/notification";

export const ROUTER = [
    {
        path: "/",
        element: <Profile/>
    },
    {
        path: "/staking",
        element: <Staking/>
    },
    {
        path: "/shop",
        element: <Shop/>
    },
    {
        path: "/leaderboard",
        element: <Leaderboard/>
    },
    {
        path: "/exchange",
        element: <Exchange/>
    },
    {
        path: "/notification",
        element: <Notification/>
    }
];